import { useDispatch } from 'react-redux';
import {
  clearArtifactStatus,
  clearArtifactTransformProfile,
  setActiveModal,
  setControlCardData,
  setCurrentContent,
  setCurrentIntent,
  setDynamicCallData,
  setEmailSent,
  setFormSubmitted,
  setIsCallChainLoaded,
  setLicense,
  setNextMissingPage,
  setProcData,
  setProgramData,
  setReportData,
  setResetChatbot,
  setScanCompleted,
  setScanJclFailed,
  setScannerErrorMessage,
  setSupportTickets,
  setUploadedArtifacts,
  setUploadedMissingArtifacts,
  setUserDetails,
} from '../redux/app-global';
import { v4 as uuidv4 } from 'uuid';
import { EAppIntent } from '../redux/app-global/app-global.types';
import { SESSION_HEADER } from '../constants/app.constant';
import setAuthHeaders from '../interceptors/auth.interceptor';
import { SharedService } from '../services/shared-service';
import localforage from 'localforage';
import { loadLicenseDetails } from '../services/global-service';

export const useChatBotUtils = () => {
  const dispatch = useDispatch();

  async function restart() {
    dispatch(setResetChatbot(true));
    dispatch(
      setCurrentIntent(
        SharedService.isOnPrem ? EAppIntent.EXTRACT_ARTIFACT : EAppIntent.HOME,
      ),
    );
    dispatch(setScanCompleted(false));
    dispatch(setScannerErrorMessage(''));
    dispatch(setActiveModal(null));
    dispatch(setUploadedArtifacts(undefined));
    dispatch(setUploadedMissingArtifacts(undefined));
    dispatch(setUserDetails(null));
    dispatch(setScanJclFailed(false));
    dispatch(setEmailSent(false));
    dispatch(setIsCallChainLoaded(false));
    dispatch(setFormSubmitted(false));
    dispatch(setReportData(undefined));
    if (!SharedService.isOnPrem) {
      dispatch(setLicense(null));
    }
    dispatch(setCurrentContent(''));
    dispatch(clearArtifactStatus());
    dispatch(setNextMissingPage({ page: undefined }));
    dispatch(clearArtifactTransformProfile());
    dispatch(setSupportTickets([]));
    dispatch(setProcData([]));
    dispatch(setProgramData([]));
    dispatch(setControlCardData([]));
    dispatch(setDynamicCallData([]));
    SharedService.missingPrograms.clear();
    SharedService.missingUtilities.clear();
    SharedService.reportAPICount = 0;
    const uuid = uuidv4();
    sessionStorage.setItem(SESSION_HEADER, uuid.toString());
    await localforage.removeItem('callChainArtifacts');
    setAuthHeaders();
    await loadLicenseDetails(dispatch);

    if (SharedService.isOnPrem) {
      window.location.href = '/';
    }
  }

  return { restart };
};
