import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { createPortal } from 'react-dom';
import { setActiveModal } from '../../../redux/app-global';
import CompareComponent from '../../../pages/intent-pages/test-transformed-jobs/test-compare-view/test-compare-view';
import { SharedService } from '../../../services/shared-service';

const CompareView = () => {
    const dispatch = useDispatch();
    const containerRef = useRef(null);

    function handleClose() {
        dispatch(setActiveModal(null));
    }
    return createPortal(
        <div
            style={{
                overflowY: 'auto',
                overflowX: 'hidden',
                position: 'fixed',
                top: 0,
                right: 0,
                left: 0,
                zIndex: 50,
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                inset: 0,
                height: '100vh',
                maxHeight: '100vh',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                paddingLeft: '10%',
            }}
        >
            <div
                style={{
                    position: 'fixed',
                    overflow: 'hidden',
                    maxWidth: '95%',
                    minWidth: '95%',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxHeight: window.innerHeight * 0.95,
                    display: 'flex',
                    borderRadius: '1.5rem',
                    flexDirection: 'column',
                    backgroundColor: 'white',
                }}
            >
                <div
                    style={{
                        flexShrink: 0,
                        borderBottom: '1px solid #ccc',
                    }}>
                    <div style={{
                        backgroundColor: "#0A61DC",
                        display: "flex",
                        alignItems: "center",
                        color: "white",
                        padding: "0 10px",
                        boxSizing: "border-box",
                        top: 0,
                        height: "4rem",

                    }}
                    >
                        <span style={{ marginLeft: "15px" }}>|
                            Comparison of Uploaded Files!</span>
                    </div>
                    <button
                        style={{
                            border: '0',
                            color: 'white',
                            fontSize: '1.5rem',
                            lineHeight: '1',
                            fontWeight: '400',
                            outline: 'none',
                            transition: 'all 0.3s ease',
                            cursor: 'pointer',
                            position: 'absolute',
                            right: 16,
                            top: 5,
                            background: 'transparent',
                        }}
                        onClick={handleClose}
                    >
                        <span
                            style={{
                                color: 'white',
                                height: '1.5rem',
                                width: '1.5rem',
                                fontSize: '3rem',
                                display: 'block',
                                outline: 'none',
                            }}
                        >
                            ×
                        </span>
                    </button>
                </div>
                <div
                    ref={containerRef}
                    style={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        marginBottom: "10px",
                        height:"100vh"
                    }}
                >
                    <CompareComponent oldString={SharedService.mfFileContent} newString={SharedService.javaFileContent} />
                </div>
            </div>
        </div >,
        document.body,
    );
};

export default CompareView;
