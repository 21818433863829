import React from 'react';
import FileTypeTable from './file-type-table';


interface Row {
    datasetName: string;
    recordLength: number;
    recordFormat: string;
    fileType: string;
    colorClass?: string;
  }
  
  interface SubTableProps {
    rows: Row[];
    downloadStatus: boolean;
  }

const SubTable: React.FC<SubTableProps> = ({ rows, downloadStatus }) => {
    const groupedRows = rows.reduce<{ INPUT: Row[], OUTPUT: Row[] }>(
        (acc, row) => {
            if (row.fileType === 'INPUT') {
                acc.INPUT.push(row);
            } else if (row.fileType === 'OUTPUT') {
                acc.OUTPUT.push(row);
            }
            return acc;
        },
        { INPUT: [], OUTPUT: [] }
    );

    return (
        <div className='bg-gray-100'>
            <div className='p-6'>
                <div className='bg-white shadow-md rounded-md'>
                    <div className='flex flex-col gap-4 p-6'>
                        <FileTypeTable
                            title="INPUT Files"
                            rows={groupedRows.INPUT}
                            downloadStatus={downloadStatus}
                            tableClassName="bg-blue-50"
                            headerClassName="text-blue-600"
                            rowClassName="bg-blue-100"
                        />
                        <FileTypeTable
                            title="OUTPUT Files"
                            rows={groupedRows.OUTPUT}
                            downloadStatus={downloadStatus}
                            tableClassName="bg-orange-50"
                            headerClassName="text-orange-600"
                            rowClassName="bg-orange-100" 
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubTable;
