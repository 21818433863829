import React from 'react';
import HelpTextCallout from '../help-text-callout/help-text-callout';

interface IContainerCardProps {
  title?: React.ReactNode;
  body: React.ReactNode;
  footer?: React.ReactNode;
  callOutText?: string;
}

const ContainerCard = (props: IContainerCardProps) => {
  return (
    <div className="shadow-md rounded border-t border-r border-b border-l border-gray-400 m-1 flex flex-col justify-between leading-normal">
      {props.title && <div className="text-md font-semibold p-2 mb-2 border-b border-gray-400">{props.title}<HelpTextCallout calloutText={props.callOutText}/></div>}
      <div className="p-4 h-full">{props.body}</div>
      {props.footer && <div className="p-4 mt-4">{props.footer}</div>}
    </div>
  );
};

export default ContainerCard;