import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { IoMdAddCircle } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';
import { FaEdit, FaSpinner } from 'react-icons/fa';
import { externalConfig } from '../../../utils/misc.utils';
import {
    PrimaryButton,
} from '@fluentui/react';


interface Artifact {
    id: number;
    artifactName: string;
    generateWith: string;
    ruleType: string;
    validateBeforeDownload: boolean;
}

const ruleTypeOptions = [
    { label: 'RuleType1', value: 'RuleType1' },
    { label: 'RuleType2', value: 'RuleType2' },
];

const generateWithOptions = [
    { label: 'DEFAULT', value: 'DEFAULT' },
    { label: 'METHOD_CONTEXT', value: 'METHOD_CONTEXT' },
    { label: 'ANNOTATION', value: 'annotation' },
];

const TransformSettings: React.FC = () => {
    const [artifacts, setArtifacts] = useState<Artifact[][]>([]);
    const [error, setError] = useState<string | null>(null);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editingArtifact, setEditingArtifact] = useState<Artifact | null>(null);
    const [loading, setLoading] = useState(true);
    const [newArtifactName, setNewArtifactName] = useState('');
    const [newGenerateWith, setNewGenerateWith] = useState('');
    const [newRuleType, setNewRuleType] = useState('');
    const [newValidateBeforeDownload, setNewValidateBeforeDownload] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const apiUrl = `${externalConfig?.scannerUrl || process.env.REACT_APP_REPORT_API_URL}/transform-settings`;

            try {
                const response = await axios.get(apiUrl);
                const rawData = response.data?.data ?? response.data ?? [];
                const groupedData = Array.isArray(rawData) ? groupArtifacts(rawData) : [];
                setArtifacts(groupedData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('An error occurred while fetching data.');
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const groupArtifacts = (data: Artifact[]): Artifact[][] => {
        const grouped: { [key: number]: Artifact[] } = {};
        data.forEach(artifact => {
            if (!grouped[artifact.id]) {
                grouped[artifact.id] = [];
            }
            grouped[artifact.id].push(artifact);
        });
        return Object.values(grouped);
    };

    const handleEditClick = (artifact: Artifact) => {
        setEditingArtifact(artifact);
        setNewArtifactName(artifact.artifactName);
        setNewGenerateWith(artifact.generateWith);
        setNewRuleType(artifact.ruleType);
        setNewValidateBeforeDownload(artifact.validateBeforeDownload);
        setIsEditModalOpen(true);

    };

    const handleSaveEdit = async () => {
        if (editingArtifact) {
            const updatedArtifact = {
                ...editingArtifact,
                artifactName: newArtifactName,
                generateWith: newGenerateWith,
                ruleType: newRuleType,
                validateBeforeDownload: newValidateBeforeDownload
            };

            try {
                const apiUrl = `${externalConfig?.scannerUrl || process.env.REACT_APP_REPORT_API_URL}/transform-settings`;
                await axios.put(apiUrl, updatedArtifact);
                setArtifacts(prevArtifacts =>
                    prevArtifacts.map(group =>
                        group.map(a => a.id === editingArtifact.id ? updatedArtifact : a)
                    )
                );
                handleCancelModal();
                setIsEditing(false);
            } catch (error) {
                console.error('Error saving artifact:', error);
                setError('An error occurred while saving the artifact.');
            }
        }
    };



    const handleAddGroupClick = () => {
        setNewArtifactName('');
        setNewGenerateWith('');
        setNewRuleType('');
        setNewValidateBeforeDownload(false);
        setIsAddModalOpen(true);
    };



    const handleSaveGroup = async () => {
        const newArtifactData: Omit<Artifact, 'id'> = {
            artifactName: newArtifactName,
            generateWith: newGenerateWith,
            ruleType: newRuleType,
            validateBeforeDownload: newValidateBeforeDownload
        };

        try {
            const apiUrl = `${externalConfig?.scannerUrl || process.env.REACT_APP_REPORT_API_URL}/transform-settings`;
            const response = await axios.post(apiUrl, newArtifactData);
            const createdArtifact: Artifact = response.data;
            setArtifacts(prevArtifacts => [...prevArtifacts, [createdArtifact]]);
            handleCancelModal();
        } catch (error) {
            console.error('Error adding new group:', error);
            setError('An error occurred while adding the new group.');
        }
    };


    const handleCancelModal = () => {
        setIsAddModalOpen(false);
        setIsEditModalOpen(false);
        setNewArtifactName('');
        setNewGenerateWith('');
        setNewRuleType('');
        setNewValidateBeforeDownload(false);
        setError(null);
        setEditingArtifact(null);
        setIsEditing(false);
    };


    return (
        
        <div className="container mx-auto p-6">
              <div className='mb-2 border rounded-lg px-8 py-8 shadow max-w-full'>
            <div className="flex justify-end mb-4">
                <PrimaryButton
                    onClick={handleAddGroupClick}
                    text={'Add Group'}
                    className="rounded-md"
                    styles={{ root: { backgroundColor: '#1363DF' } }}
                />
            </div>
            <div className="mx-auto">
                <div className="max-w-5xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
                    <header className="p-6 bg-white text-gray-800">
                        <h2 className="text-3xl font-semibold mt-4">Default Setting Group</h2>
                    </header>
                    <hr className="mt-4 border-t border-gray-300" />
                    <div className="overflow-x-auto p-6">
                        <table className="min-w-full bg-white border-2 border-white-400 rounded-lg">
                            <thead className="bg-white text-white-800 border-b-2 border-white-400">
                                <tr className="bg-white border-b">
                                    <td className="py-3 px-6 font-semibold text-lg">Setting</td>
                                    <td className="py-3 px-6 font-semibold text-lg">Value</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="bg-white border-b">
                                    <td className="py-3 px-6 font-semibold text-lg">Artifact Name</td>
                                    <td className="py-3 px-6">
                                        <input
                                            type='text'
                                            name="artifactName"
                                            value="Default Artifact"
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 transition duration-300"
                                            disabled={!isEditing}
                                        />
                                    </td>
                                </tr>
                                <tr className="bg-white border-b">
                                    <td className="py-3 px-6 font-semibold text-lg">Rule Type</td>
                                    <td className="py-3 px-6">
                                        <Select
                                            className="w-full"
                                            classNamePrefix="react-select"
                                            defaultValue={{ label: 'Default RuleType1', value: 'Default RuleType1' }}
                                            options={[
                                                { label: 'Default RuleType1', value: 'Default RuleType1' },
                                                { label: 'Default RuleType2', value: 'Default RuleType2' },
                                            ]}
                                            isDisabled
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: '8px',
                                                    borderColor: '#d1d5db',
                                                    boxShadow: 'none',
                                                    '&:hover': {
                                                        borderColor: '#3b82f6'
                                                    }
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    borderRadius: '8px'
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: state.isSelected ? '#3b82f6' : '#ffffff',
                                                    color: state.isSelected ? '#ffffff' : '#333333',
                                                    '&:hover': {
                                                        backgroundColor: '#f3f4f6'
                                                    }
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: '#333333'
                                                })
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr className="bg-white border-b">
                                    <td className="py-3 px-6 font-semibold text-lg text-gray-700">Validate Before Download</td>
                                    <td className="py-3 px-6">
                                        <Select
                                            className="w-full"
                                            classNamePrefix="react-select"
                                            defaultValue={{ label: 'Yes', value: true }}
                                            isDisabled
                                            options={[
                                                { label: 'Yes', value: true },
                                                { label: 'No', value: false }
                                            ]}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: '8px',
                                                    borderColor: '#d1d5db',
                                                    boxShadow: 'none',
                                                    '&:hover': {
                                                        borderColor: '#3b82f6'
                                                    }
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    borderRadius: '8px'
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: state.isSelected ? '#3b82f6' : '#ffffff',
                                                    color: state.isSelected ? '#ffffff' : '#333333',
                                                    '&:hover': {
                                                        backgroundColor: '#f3f4f6'
                                                    }
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: '#333333'
                                                })
                                            }}
                                        />
                                    </td>
                                </tr>


                                <tr className="bg-white border-b">
                                    <td className="py-3 px-6 font-semibold text-lg text-gray-700">Generate With</td>
                                    <td className="py-3 px-6">
                                        <Select
                                            className="w-full"
                                            classNamePrefix="react-select"
                                            defaultValue={{ label: 'DEFAULT', value: 'DEFAULT' }}
                                            isDisabled
                                            options={[
                                                { label: 'DEFAULT', value: 'DEFAULT' },
                                                { label: 'METHOD_CONTEXT', value: 'METHOD_CONTEXT' },
                                                { label: 'ANNOTATION', value: 'ANNOTATION' },
                                            ]}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: '8px',
                                                    borderColor: '#d1d5db',
                                                    boxShadow: 'none',
                                                    '&:hover': {
                                                        borderColor: '#3b82f6'
                                                    }
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    borderRadius: '8px'
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: state.isSelected ? '#3b82f6' : '#ffffff',
                                                    color: state.isSelected ? '#ffffff' : '#333333',
                                                    '&:hover': {
                                                        backgroundColor: '#f3f4f6'
                                                    }
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: '#333333'
                                                })
                                            }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <br/>
            {loading ? (
                <div className="flex flex-col items-center">
                    <FaSpinner className="animate-spin text-4xl text-gray-600" />
                    <p className="mt-4 text-gray-600">Loading...</p>
                </div>
            ) : (
                artifacts.map((group, groupIndex) => (
                    <div key={groupIndex} className="mb-6">
                        <div className="max-w-5xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden border-0 border-gray-400">
                            <header className="p-6 bg-white border-b">
                                <h2 className="text-3xl font-semibold mt-4">Group {groupIndex + 1}</h2>
                            </header>
                            <div className="p-6">
                                {group.map((artifact, artifactIndex) => (
                                    <table key={artifactIndex} className="min-w-full bg-white border-2 rounded-lg mb-4">
                                        <thead>
                                            <tr className="bg-white border-b">
                                                <td className="py-3 px-6 font-semibold text-lg">Setting</td>
                                                <td className="py-3 px-6 font-semibold text-lg">Value</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="bg-white border-b">
                                                <td className="py-3 px-6 font-semibold text-lg">Artifact Name</td>
                                                <td className="py-3 px-6">
                                                    <input
                                                        type='text'
                                                        name="artifactName"
                                                        value={artifact.artifactName}
                                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 transition duration-300"
                                                        disabled={!isEditing}
                                                    />
                                                </td>
                                            </tr>
                                            <tr className="bg-white border-b">
                                                <td className="py-3 px-6 font-semibold text-lg">Rule Type</td>
                                                <td className="py-3 px-6">
                                                    <Select
                                                        className="w-full"
                                                        classNamePrefix="react-select"
                                                        value={{ label: artifact.ruleType, value: artifact.ruleType }}
                                                        options={ruleTypeOptions}
                                                        isDisabled
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                borderRadius: '8px',
                                                                borderColor: '#d1d5db',
                                                                boxShadow: 'none',
                                                                '&:hover': {
                                                                    borderColor: '#3b82f6'
                                                                }
                                                            }),
                                                            menu: (provided) => ({
                                                                ...provided,
                                                                borderRadius: '8px'
                                                            }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                backgroundColor: state.isSelected ? '#3b82f6' : '#ffffff',
                                                                color: state.isSelected ? '#ffffff' : '#333333',
                                                                '&:hover': {
                                                                    backgroundColor: '#f3f4f6'
                                                                }
                                                            }),
                                                            singleValue: (provided) => ({
                                                                ...provided,
                                                                color: '#333333'
                                                            })
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr className="bg-white border-b">
                                                <td className="py-3 px-6 font-semibold text-lg text-gray-700">Validate Before Download</td>
                                                <td className="py-3 px-6">
                                                    <Select
                                                        className="w-full"
                                                        classNamePrefix="react-select"
                                                        value={{ label: artifact.validateBeforeDownload ? 'Yes' : 'No', value: artifact.validateBeforeDownload }}
                                                        isDisabled
                                                        options={[
                                                            { label: 'Yes', value: true },
                                                            { label: 'No', value: false }
                                                        ]}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                borderRadius: '8px',
                                                                borderColor: '#d1d5db',
                                                                boxShadow: 'none',
                                                                '&:hover': {
                                                                    borderColor: '#3b82f6'
                                                                }
                                                            }),
                                                            menu: (provided) => ({
                                                                ...provided,
                                                                borderRadius: '8px'
                                                            }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                backgroundColor: state.isSelected ? '#3b82f6' : '#ffffff',
                                                                color: state.isSelected ? '#ffffff' : '#333333',
                                                                '&:hover': {
                                                                    backgroundColor: '#f3f4f6'
                                                                }
                                                            }),
                                                            singleValue: (provided) => ({
                                                                ...provided,
                                                                color: '#333333'
                                                            })
                                                        }}
                                                    />
                                                </td>
                                            </tr>


                                            <tr className="bg-white border-b">
                                                <td className="py-3 px-6 font-semibold text-lg text-gray-700">Generate With</td>
                                                <td className="py-3 px-6">
                                                    <Select
                                                        className="w-full"
                                                        classNamePrefix="react-select"
                                                        value={{ label: artifact.generateWith, value: artifact.generateWith }}
                                                        isDisabled
                                                        options={[
                                                            { label: 'Default', value: 'default' },
                                                            { label: 'Method Context', value: 'method_context' },
                                                            { label: 'Annotation', value: 'annotation' },
                                                        ]}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                borderRadius: '8px',
                                                                borderColor: '#d1d5db',
                                                                boxShadow: 'none',
                                                                '&:hover': {
                                                                    borderColor: '#3b82f6'
                                                                }
                                                            }),
                                                            menu: (provided) => ({
                                                                ...provided,
                                                                borderRadius: '8px'
                                                            }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                backgroundColor: state.isSelected ? '#3b82f6' : '#ffffff',
                                                                color: state.isSelected ? '#ffffff' : '#333333',
                                                                '&:hover': {
                                                                    backgroundColor: '#f3f4f6'
                                                                }
                                                            }),
                                                            singleValue: (provided) => ({
                                                                ...provided,
                                                                color: '#333333'
                                                            })
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr className="bg-white border-b">
                                                <td className="py-3 px-6 font-semibold text-lg text-gray-700"></td>
                                                <td className="py-3 px-6 flex justify-end space-x-2">
                                                    <button
                                                        onClick={() => handleEditClick(artifact)}
                                                        className="flex items-center bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg shadow-lg transition duration-300"
                                                    >
                                                        <FaEdit className="mr-2" />
                                                        Modify
                                                    </button>
                                                    <button
                                                        // onClick={() => handleRemoveArtifact(artifact.id)}
                                                        className="flex items-center border-2 border-red-600 text-red-600 font-semibold py-2 px-6 rounded-lg shadow-md hover:bg-red-100 transition duration-300"
                                                    >
                                                        <IoClose className="mr-2" />
                                                        Remove
                                                    </button>
                                                </td>
                                            </tr>
                                        </tfoot>

                                    </table>

                                ))}
                            </div>
                        </div>
                    </div>
                ))
            )}
            {isAddModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full">
                        <h2 className="text-2xl font-semibold mb-4">Add New Group</h2>
                        <hr className="mt-4 border-t border-gray-300" />
                        <br />
                        <form>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2">Artifact Name</label>
                                <input
                                    type="text"
                                    value={newArtifactName}
                                    onChange={(e) => setNewArtifactName(e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 transition duration-300"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2">Rule Type</label>
                                <Select
                                    className="w-full"
                                    classNamePrefix="react-select"
                                    value={ruleTypeOptions.find(option => option.value === newRuleType)}
                                    onChange={(option) => setNewRuleType(option?.value || '')}
                                    options={ruleTypeOptions}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderRadius: '8px',
                                            borderColor: '#d1d5db',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                borderColor: '#3b82f6'
                                            }
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            borderRadius: '8px'
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isSelected ? '#3b82f6' : '#ffffff',
                                            color: state.isSelected ? '#ffffff' : '#333333',
                                            '&:hover': {
                                                backgroundColor: '#f3f4f6'
                                            }
                                        }),
                                        singleValue: (provided) => ({
                                            ...provided,
                                            color: '#333333'
                                        })
                                    }}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2">Validate Before Download</label>
                                <Select
                                    className="w-full"
                                    classNamePrefix="react-select"
                                    // value={{ label: newValidateBeforeDownload ? 'Yes' : 'No', value: newValidateBeforeDownload }}
                                    onChange={(option) => setNewValidateBeforeDownload(option?.value || false)}
                                    options={[
                                        { label: 'Yes', value: true },
                                        { label: 'No', value: false }
                                    ]}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderRadius: '8px',
                                            borderColor: '#d1d5db',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                borderColor: '#3b82f6'
                                            }
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            borderRadius: '8px'
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isSelected ? '#3b82f6' : '#ffffff',
                                            color: state.isSelected ? '#ffffff' : '#333333',
                                            '&:hover': {
                                                backgroundColor: '#f3f4f6'
                                            }
                                        }),
                                        singleValue: (provided) => ({
                                            ...provided,
                                            color: '#333333'
                                        })
                                    }}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2">Generate With</label>
                                <Select
                                    className="w-full"
                                    classNamePrefix="react-select"
                                    value={generateWithOptions.find(option => option.value === newGenerateWith)}
                                    onChange={(option) => setNewGenerateWith(option?.value || '')}
                                    options={generateWithOptions}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderRadius: '8px',
                                            borderColor: '#d1d5db',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                borderColor: '#3b82f6'
                                            }
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            borderRadius: '8px'
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isSelected ? '#3b82f6' : '#ffffff',
                                            color: state.isSelected ? '#ffffff' : '#333333',
                                            '&:hover': {
                                                backgroundColor: '#f3f4f6'
                                            }
                                        }),
                                        singleValue: (provided) => ({
                                            ...provided,
                                            color: '#333333'
                                        })
                                    }}
                                />
                            </div>
                        </form>
                        <div className="flex justify-end mt-4">
                            <button
                                onClick={handleSaveGroup}
                                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg shadow-lg transition duration-300"
                            >
                                Save
                            </button>
                            <button
                                onClick={handleCancelModal}
                                className="ml-4 border border-gray-500 text-gray-500 hover:bg-gray-500 hover:text-white font-semibold py-2 px-4 rounded-lg shadow-lg transition duration-300"
                            >
                                Cancel
                            </button>

                        </div>
                    </div>
                </div>
            )}

            {isEditModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full">
                        <h2 className="text-2xl font-semibold mb-4">Edit Group</h2>
                        <hr className="mt-4 border-t border-gray-300" />
                        <br />
                        <form>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2">Artifact Name</label>
                                <input
                                    type="text"
                                    value={newArtifactName}
                                    onChange={(e) => setNewArtifactName(e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 transition duration-300"
                                />
                            </div>

                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2">Rule Type</label>
                                <Select
                                    className="w-full"
                                    classNamePrefix="react-select"
                                    value={ruleTypeOptions.find(option => option.value === newRuleType)}
                                    onChange={(option) => setNewRuleType(option?.value || '')}
                                    options={ruleTypeOptions}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderRadius: '8px',
                                            borderColor: '#d1d5db',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                borderColor: '#3b82f6'
                                            }
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            borderRadius: '8px'
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isSelected ? '#3b82f6' : '#ffffff',
                                            color: state.isSelected ? '#ffffff' : '#333333',
                                            '&:hover': {
                                                backgroundColor: '#f3f4f6'
                                            }
                                        }),
                                        singleValue: (provided) => ({
                                            ...provided,
                                            color: '#333333'
                                        })
                                    }}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2">Validate Before Download</label>
                                <Select
                                    className="w-full"
                                    classNamePrefix="react-select"
                                    value={{ label: newValidateBeforeDownload ? 'Yes' : 'No', value: newValidateBeforeDownload }}
                                    onChange={(option) => setNewValidateBeforeDownload(option?.value || false)}
                                    options={[
                                        { label: 'Yes', value: true },
                                        { label: 'No', value: false }
                                    ]}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderRadius: '8px',
                                            borderColor: '#d1d5db',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                borderColor: '#3b82f6'
                                            }
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            borderRadius: '8px'
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isSelected ? '#3b82f6' : '#ffffff',
                                            color: state.isSelected ? '#ffffff' : '#333333',
                                            '&:hover': {
                                                backgroundColor: '#f3f4f6'
                                            }
                                        }),
                                        singleValue: (provided) => ({
                                            ...provided,
                                            color: '#333333'
                                        })
                                    }}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2">Generate With</label>
                                <Select
                                    className="w-full"
                                    classNamePrefix="react-select"
                                    value={generateWithOptions.find(option => option.value === newGenerateWith)}
                                    onChange={(option) => setNewGenerateWith(option?.value || '')}
                                    options={generateWithOptions}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderRadius: '8px',
                                            borderColor: '#d1d5db',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                borderColor: '#3b82f6'
                                            }
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            borderRadius: '8px'
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isSelected ? '#3b82f6' : '#ffffff',
                                            color: state.isSelected ? '#ffffff' : '#333333',
                                            '&:hover': {
                                                backgroundColor: '#f3f4f6'
                                            }
                                        }),
                                        singleValue: (provided) => ({
                                            ...provided,
                                            color: '#333333'
                                        })
                                    }}
                                />
                            </div>
                        </form>
                        <div className="flex justify-end mt-4">
                            <button
                                onClick={handleSaveEdit}
                                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg shadow-lg transition duration-300"
                            >
                                Save
                            </button>
                            <button
                                onClick={handleCancelModal}
                                className="ml-4 border border-gray-500 text-gray-500 hover:bg-gray-500 hover:text-white font-semibold py-2 px-4 rounded-lg shadow-lg transition duration-300"
                            >
                                Cancel
                            </button>

                        </div>
                    </div>
                </div>
            )}
        </div>
        </div>
       
    );
};

export default TransformSettings;
