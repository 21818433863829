import React, { useState } from "react";
import { MdCompareArrows } from "react-icons/md";
import { useDispatch } from "react-redux";
import { setActiveModal } from "../../../../redux/app-global";
import { EAppModal } from "../../../../redux/app-global/app-global.types";
import { SharedService } from "../../../../services/shared-service";
import SubTable from "./sub-table";
import Table from "./table";


const TransformedJobs = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([
    {
      jobName: 'CFBOOKSJ',
      executionType: false,
      validated: 'PENDING',
      expanded: false,
      downloadStatus: false,
      rows: [
        {
          datasetName: 'CLFQA.BASELINE.BOOKS.VSAM.VB',
          recordLength: 302,
          recordFormat: 'Variable Block',
          fileType: 'INPUT',
          colorClass: 'text-blue-400',
        },
        {
          datasetName: 'CLFQA.BASELINE.BOOKS.VSAM',
          recordLength: 302,
          recordFormat: 'VSAM',
          fileType: 'INPUT',
          colorClass: 'text-blue-400',
        },
        {
          datasetName: 'CLFQA.BASELINE.REPOUT.MF',
          recordLength: 132,
          recordFormat: 'Fixed',
          fileType: 'OUTPUT',
          colorClass: 'text-green-500',
        },
      ],
    },
  ]);
  const handleSwitchChange = (index) => {
    const updatedData = [...data];
    updatedData[index].executionType = !updatedData[index].executionType;
    setData(updatedData);
  };

  const handleExpandToggle = (index) => {
    const updatedData = [...data];
    updatedData[index].expanded = !updatedData[index].expanded;
    setData(updatedData);
  };

  const folderOpen = async (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const files = Array.from(event.target.files || []);

    if (files.length > 2) {
      alert('You can only select up to two files.');
      setLoading(false);
      return;
    }
    
    if(files.length < 2) {
      setLoading(false);
      return;
    }
    const readFileAsText = (file: File): Promise<string> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target?.result as string);
        reader.onerror = (error) => reject(error);
        reader.readAsText(file);
      });
    };

    try {
      const promises = files.map((file: File) => {
        if (file.name.endsWith('.CF')) {
          return readFileAsText(file).then((content) => {
            SharedService.javaFileContent = content;
          });
        } else if (file.name.endsWith('.MF')) {
          return readFileAsText(file).then((content) => {
            SharedService.mfFileContent = content;
          });
        }
        return Promise.resolve();
      });
      await Promise.all(promises);

      setTimeout(() => {
        setLoading(false);
      }, 2000);
      if (SharedService.javaFileContent === SharedService.mfFileContent) {
        dispatch(setActiveModal(EAppModal.COMPARE_SUCCESS));
        const newFileData = [...data];
        newFileData[index] = {
          ...newFileData[index],
          validated: "SUCCESS",
          downloadStatus: true,
          
        };
        setData(newFileData);
      } else {
        dispatch(setActiveModal(EAppModal.COMPARE_FAIL));
        const newFileData = [...data];
        newFileData[index] = {
          ...newFileData[index],
          validated: "FAILED",
          downloadStatus: true,
        };
        setData(newFileData);
      }
    } catch (error) {
      console.error("Error reading files:", error);
      setLoading(false);
    }
  };
  return (
    <div>
      <div
        className="rounded-lg border bg-card text-card-foreground shadow-sm"
        data-v0-t="card"
      >
        <div className="flex flex-col space-y-4 p-6 bg-[#0a61dc] text-white">
          <div className='flex flex-row'>
            <h2 className="text-2xl font-bold text-white">Validation Dashboard</h2>
          </div>
          <p className="text-sm text-white">You can use the Validation Dashboard to export the necessary MainFrame input and output files for running the code. After execution of the code, upload both your Java and MainFrame output files if ran locally. Our comparison tool will then check the two files and confirm whether functional equivalence has been met.</p>
        </div>
        <hr />
        <div className="p-6">
          <div className="relative w-full overflow-auto">
              <Table
                data={data}
                onSwitchChange={handleSwitchChange}
                onExpandToggle={handleExpandToggle}
              />
              {data.map((job, index) => (
                <div key={index}>
                  <div
                    className={`overflow-hidden transition-max-height duration-500 ease-in-out ${job.expanded ? 'max-h-screen' : 'max-h-0'}`}
                  >
                    {job.expanded && (
                      <SubTable
                        rows={job.rows}
                        downloadStatus = {job.downloadStatus}
                      />
                    )}
                  </div>
                  <div className="flex justify-center gap-4 py-4 items-center">
                    <div className="mx-auto mt-4 flex items-center leading-none justify-center gap-xs w-[14rem] bg-cta text-white rounded-md shadow-md p-4">
                      <label className="file-label flex items-center cursor-pointer" htmlFor="file2">
                        <input
                          type="file"
                          style={{ display: "none" }}
                          id="file2"
                          onChange={(e)=>folderOpen(index,e)}
                          multiple
                        />
                        <MdCompareArrows className="text-[18px] mr-2" />
                        <span className="text-[14px] font-semibold">Upload Files and Compare</span>
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
  )
}
export default TransformedJobs;