import {
  Checkbox,
  initializeIcons,
  PrimaryButton,
  Toggle,
  TooltipHost,
} from '@fluentui/react';
import { useEffect, useRef, useState } from 'react';
import Dropdown from '../../../components/custom-dropdown/custom-dropdown';
import ReusableTable from '../../../components/missing-artifacts-table/missing-artifacts-table';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCurrentIntent,
  setNextMissingPage,
  setNextMissingPageTest,
} from '../../../redux/app-global';
import { EAppIntent, TableData } from '../../../redux/app-global/app-global.types';
import React from 'react';
import { RootState } from '../../../redux/store';
import StepperComponent from '../../../components/stepper-component/stepper-component';
import MissingUploadList, { UploadMissingListRef } from '../../../components/missing-artifact-upload/missing-artifact-upload';
import { helpTexts } from '../../../helpTexts';
initializeIcons();

const MissingMetaData = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [toggleValue, setToggleValue] = useState<boolean | undefined>(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const [data, setData] = useState<TableData[]>([]);
  const isMounted = useRef(false);
  const missingPages = useSelector((state:RootState)=>state.appGlobal.missingPages);
  const dispatch = useDispatch();
  const pageHelpText = helpTexts[0].MISSING_METADATA;
  const uploadMissingListRef = useRef<UploadMissingListRef>(null);
    const triggerFileUpload = (event, itemKey) => {
        if (uploadMissingListRef.current) {
        uploadMissingListRef.current.handleFileUpload(event, itemKey);
        }
    };
  const handleCheckboxChange = key => {
    setSelectedRows(prev => ({
      ...prev,
      [key]: !prev[key],
    }));
    setData(prevData =>
      prevData.map(item =>
        item.key === key
          ? { ...item, status: !selectedRows[key] ? 'Ignore' : 'Active' }
          : item,
      ),
    );
  };
  const handleRecordLength = (event, key) => {
    const value = event.target.value;
    setData(prevData =>
      prevData.map(item =>
        item.key === key ? { ...item, recordLength: value } : item,
      ),
    );
  };
  const handleClusterName = (event, key) => {
    const value = event.target.value;
    setData(prevData =>
      prevData.map(item =>
        item.key === key ? { ...item, clusterName: value } : item,
      ),
    );
  };
  const sendMetadataInfo = async () => {
    const formData = new FormData();

    data.forEach(row => {
      formData.append('name', row.name);
      formData.append('status', row.status || '');
      formData.append('referredIn', JSON.stringify(row.requiredBy));
      formData.append('recordLength', row.recordLength || '');
      formData.append('recordFormat', row.recordFormat || '');
      formData.append('clusterName', row.clusterName || '');
    });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_REPORT_API_URL}/missing-artifact/METADATA`,
        formData,
      );
    } catch (err) {
      console.log(err);
    } finally {
        const currentIndex = missingPages.findIndex((page) => page.status === true);
        if (currentIndex !== -1 && currentIndex < missingPages.length - 1) {
            const nextIndex = currentIndex + 1;
            const nextPage = missingPages[nextIndex];
            dispatch(setCurrentIntent(nextPage.key));
            dispatch(setNextMissingPage({
                page: nextPage.key,
                }));
            const updatedMissingPages = missingPages.map((page, index) =>
              index === nextIndex ? { ...page, status: true }
              : index === currentIndex ? { ...page, status: false }
              : page
            );
            dispatch(setNextMissingPageTest(updatedMissingPages));
          } else {
            dispatch(setCurrentIntent(EAppIntent.ARTIFACT_UPLOAD));
            dispatch(
                setNextMissingPage({
                page: undefined,
                }),
            );
          }
    //   dispatch(setCurrentIntent(EAppIntent.REPORT));
    //   dispatch(
    //     setNextMissingPage({
    //       page: undefined,
    //     }),
    //   );
    }
  };
  useEffect(() => {
    async function getMissingMetadata() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_REPORT_API_URL}/missing-artifact/METADATA`,
        );
        const transformedData: TableData[] = response.data?.data.map(
          (item: any, index: number) => ({
            key: index,
            recordFormat: 'Fixed Record',
            name: item.name,
            requiredBy: item.referredIn,
            recordLength: '',
            clusterName: '',
            status: 'Active',
          }),
        );
        setData(transformedData);
        return;
      } catch (err) {
        console.error(err);
      }
    }
    if (!isMounted.current) {
      getMissingMetadata();
      isMounted.current = true;
    }
  }, []);
  const handleSelectAllCheckboxChange = (event, checked) => {
    setSelectAll(checked);
    const newSelectedRows = {};
    data.forEach(item => {
      newSelectedRows[item.key] = checked;
    });
    const newData = data.map(item => {
      newSelectedRows[item.key] = checked;
      return { ...item, status: checked ? 'Ignore' : 'Active' };
    });
    setSelectedRows(newSelectedRows);
    setData(newData);
  };
  const options = [
    'Fixed Record',
    'Fixed Block',
    'Fixed Block, ANSI Control',
    'Fixed Block, Machine Control',
    'Variable Record',
    'Variable Block',
    'Variable Block, ANSI Control',
    'Variable Block, Machine Control',
    'Variable Block spanned',
    'VSAM',
  ];
  const handleOptionSelect = (option, index) => {
    const newData = [...data];
    newData[index].recordFormat = option;
    setData(newData);
  };
  const headers: React.ReactNode[] = [
    <Checkbox
      checked={selectAll}
      onChange={handleSelectAllCheckboxChange}
      className="mt-2"
      title="Ignore All"
      label="Ignore All"
      styles={{
        checkbox: { borderRadius: '50%', padding: '5px', marginRight: '15px' },
        label: { fontWeight: '500' },
      }}
      key="1"
    />,
    'Metadata Name',
    'Artifact',
    'Max Record Length',
    'Record Format',
    'Cluster Name',
  ];

  const rowFields = item => [
    <Checkbox
      checked={selectedRows[item.key] || false}
      onChange={() => handleCheckboxChange(item.key)}
      className="mt-2"
      title="Ignore"
      label="Ignore"
      key={item.key}
      styles={{
        checkbox: { borderRadius: '50%', padding: '5px', marginRight: '15px' },
        label: { fontWeight: '500' },
      }}
    />,
    <div
      className={`text-sm ${selectedRows[item.key] ? 'text-gray-400' : 'text-gray-900'}`}
      key={item.key}
    >
      {item.name}
    </div>,
    item.requiredBy?.jcl.length + item.requiredBy?.step.length <= 4 ? (
      <div
        className={`text-sm ${selectedRows[item.key] ? 'text-gray-400' : 'text-gray-900'}`}
        key={item.key}
      >
        {item.requiredBy?.jcl.join(', ')}, {item.requiredBy?.step.join(', ')}
      </div>
    ) : (
      <div key={item.key}>
        <TooltipHost
          id={`tooltip-${selectedRows[item.key]}`}
          content={`${item.requiredBy?.jcl.slice(4, item.requiredBy?.jcl.length - 1).join(', ')}, ${item.requiredBy?.step}`}
        >
          <div
            className={`text-sm ${selectedRows[item.key] ? 'text-gray-400' : 'text-gray-900'}`}
          >
            {item.requiredBy?.jcl.slice(0, 3).join(', ')}
          </div>
          <span
            className={`text-sm ${selectedRows[item.key] ? 'text-gray-400' : 'text-gray-900'}`}
          >
            ... and {item.requiredBy?.jcl.length + item.requiredBy?.step - 3}{' '}
            more
          </span>
        </TooltipHost>
      </div>
    ),
    <input
      key={item.key}
      type="number"
      placeholder="Record Length"
      className={`border border-gray-200 w-full px-2 text-sm py-2 ${selectedRows[item.key] ? 'cursor-not-allowed' : 'cursor-text'}`}
      onChange={e => handleRecordLength(e, item.key)}
    />,
    <Dropdown
      key={item.key}
      options={options}
      selectedOption={item.recordFormat || options[0]}
      onOptionSelect={option => handleOptionSelect(option, item.key)}
    />,
    <input
      key={item.key}
      type="text"
      placeholder="Cluster Name"
      className={`border border-gray-200 w-full px-2 text-sm py-2 ${selectedRows[item.key] ? 'cursor-not-allowed' : 'cursor-text'}`}
      onChange={e => handleClusterName(e, item.key)}
    />,
  ];
  const changeDisplay = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean,
  ) => {
    setToggleValue(checked);
  };
  return (
    <div className={`container p-6 ${isMobile ? 'sm:px-6' : 'lg:px-8'} max-w-full`}>
        <div className='p-2 mb-4'>
       <StepperComponent /> 
      </div>
      <div className='mb-2 border rounded-lg px-8 py-8 shadow max-w-full'>
      <h1 className="text-3xl font-semibold mb-4">
        Missing Artifacts Workspace
      </h1>
      <hr />
      <div className="flex flex-col gap-4">
        <div className="text-left mt-4 gap-4 text-l font-semibold">
          Missing Metadata Information
        </div>
        <div>
          Provide dataset for the missing Metadata below or upload the missing
          Metadata.
        </div>
        <div className="flex items-center gap-4">
          <span className="text-sm">Upload Metadata</span>
          <Toggle className="mt-2" onChange={changeDisplay} />
          <span className="text-sm">Extract Metadata from Mainframe</span>
        </div>
        {!toggleValue && (
          <>
            <div className="font-semibold mt-4">Upload Metadata as File</div>
            <hr />
          </>
        )}
        <div className='w-full relative overflow-auto'>
        <ReusableTable items={data} headers={headers} rowFields={rowFields} helpTexts={pageHelpText}/>
        </div>
        {!toggleValue ? (
            <MissingUploadList ref={uploadMissingListRef} setData={setData} type='Metadata' data={data} pageHelpText={helpTexts}/>
        ) : (
          <div>
            <h3>Enter the comma seperated Dataset names</h3>
            <input
              type="text"
              className="border border-gray-300 rounded-md w-1/4 p-2 mt-2"
              placeholder="Enter dataset names..."
            />
          </div>
        )}
      </div>
      {(data.every(
        item =>
          item.status !== 'Active' ||
          (item.recordLength !== '' && item.status !== 'Active') ||
          item.clusterName !== '',
      ) ||
        toggleValue ||
        true) && (
        <PrimaryButton
          onClick={sendMetadataInfo}
          text={toggleValue ? 'Generate Extraction JCL' : 'Save and Continue'}
          className="mt-8 rounded-md"
          styles={{ root: { backgroundColor: '#1363DF' } }}
        />
      )}
      </div>
    </div>
  );
};
export default MissingMetaData;
