import React, { useEffect, useState } from 'react';
import { ISubTable } from '../table';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { FaDownload } from 'react-icons/fa';
import {
  setArtifactStatus,
  setArtifactStatusObject,
  setArtifactTransformProfile,
} from '../../../../../redux/app-global';
import { EArtifactStatus } from '../../../../../redux/app-global/app-global.types';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import useSubTable from './useSubTable';
import axios from 'axios';
import { title } from 'process';
import { downloadCode } from '../../transformation.service';

const SubTable = (table: ISubTable) => {
  const dispatch = useDispatch();
  const artifactStatus = useSelector(
    (state: RootState) => state.appGlobal.artifactStatus || {},
  );
  const artifactTransformProfile = useSelector(
    (state: RootState) => state.appGlobal.artifactTransformProfile || {},
  );
  const [showCancelConfirmation, setShowCancelConfirmation] = useState<
    string | null
  >(null);
  const [showBulkCancelConfirmation, setShowBulkCancelConfirmation] =
    useState<boolean>(false);
  const { showCallChainModal } = useSubTable();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filterOption, setFilterOption] = useState<string>('All');

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [progress, setProgress] = useState<Record<string, number>>({});
  const [loading, setLoading] = useState<Record<string, boolean>>({});
  const [downloading, setDownloading] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const intervalIds: Record<string, NodeJS.Timeout> = {};

    Object.keys(artifactStatus).forEach(name => {
      if (
        artifactStatus[name] === EArtifactStatus.INPROGRESS &&
        !intervalIds[name]
      ) {
        const intervalTime = ['EXECRPT', 'TRADERPT', 'TRDPB000', 'CFBOOKSJ'].includes(
          name.toUpperCase(),
        )
          ? 100
          : 10000;
        intervalIds[name] = setInterval(() => {
          setProgress(prevProgress => {
            const newProgress = { ...prevProgress };
            newProgress[name] = (newProgress[name] || 0) + 1;
            if (newProgress[name] >= 100) {
              clearInterval(intervalIds[name]);
              newProgress[name] = 100;
              dispatch(
                setArtifactStatus({
                  key: name,
                  status: EArtifactStatus.SUCCESS,
                }),
              );
            }
            return newProgress;
          });
        }, intervalTime);
      }
    });

    return () => {
      Object.values(intervalIds).forEach(clearInterval);
    };
  }, [artifactStatus, dispatch]);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const confirmCancel = (name: string) => {
    setShowCancelConfirmation(name);
  };

  const handleCancel = async (name: string) => {
    setLoading(prev => ({ ...prev, [name]: true }));
    try {
      const api = `${process.env.REACT_APP_REPORT_API_URL}/cancel-transformation`;
      const response = await axios.post(api, [
        {
          artifactName: name,
          type: 'string',
        },
      ]);

      if (response.data.status === 'SUCCESS') {
        setShowCancelConfirmation(null);
        dispatch(
          setArtifactStatus({
            key: name,
            status: EArtifactStatus.DEFAULT,
          }),
        );
        setTimeout(() => {
          setProgress(prevProgress => ({ ...prevProgress, [name]: 0 }));
        });
      } else {
        console.log('Failed to start the transform process');
      }
    } catch (err) {
      console.log('Error:', err);
    } finally {
      setLoading(prev => ({ ...prev, [name]: false }));
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterOption(event.target.value);
  };

  const filterRows = (name: string) => {
    if (
      searchQuery &&
      !name.toLowerCase().includes(searchQuery.toLowerCase())
    ) {
      return false;
    }
    if (
      filterOption === 'Transformation in progress' &&
      artifactStatus[name] !== EArtifactStatus.INPROGRESS
    ) {
      return false;
    }
    if (
      filterOption === 'Transformation finished' &&
      artifactStatus[name] !== EArtifactStatus.SUCCESS
    ) {
      return false;
    }
    if (
      filterOption === 'Transformation failed' &&
      artifactStatus[name] !== EArtifactStatus.ERROR
    ) {
      return false;
    }
    return true;
  };

  const handleBulkTransform = async () => {
    try {
      const object: Record<string, EArtifactStatus> = {};
      const payload: any = [];
      table.rows.forEach(row => {
        if (filterRows(row.name)) {
          object[row.name] = EArtifactStatus.INPROGRESS;
          payload.push({ name: row.name, type: 'string' });
        }
      });
      const api = `${process.env.REACT_APP_REPORT_API_URL}/strat-transformation`;
      const response = await axios.post(api, payload);

      if (response.data.status === 'SUCCESS') {
        console.log('Successfully started the transform process');
        dispatch(setArtifactStatusObject(object));
      } else {
        console.log('Failed to start the transform process');
      }
    } catch (err) {
      console.log('Error:', err);
    }
  };

  const handleBulkCancel = () => {
    setShowBulkCancelConfirmation(true);
  };

  const confirmBulkCancel = async () => {
    try {
      const payload: any = [];
      const object: Record<string, EArtifactStatus> = {};
      table.rows.forEach(row => {
        if (filterRows(row.name)) {
          object[row.name] = EArtifactStatus.DEFAULT;
        }
      });
      const api = `${process.env.REACT_APP_REPORT_API_URL}/cancel-transformation`;
      const response = await axios.post(api, payload);

      if (response.data.status === 'SUCCESS') {
        dispatch(setArtifactStatusObject(object));
        setShowBulkCancelConfirmation(false);
      } else {
        console.log('Failed to start the transform process');
      }
    } catch (err) {
      console.log('Error:', err);
    }
  };

  const handleTransformProfileChange = (name: string, profile: string) => {
    dispatch(setArtifactTransformProfile({ key: name, value: profile }));
  };

  const handleToggleChange = async (name: string) => {
    setLoading(prev => ({ ...prev, [name]: true }));
    try {
      const api = `${process.env.REACT_APP_REPORT_API_URL}/strat-transformation`;
      const response = await axios.post(api, [
        {
          artifactName: name,
          type: 'string',
        },
      ]);

      if (response.data.status === 'SUCCESS') {
        console.log('Successfully started the transform process');
        dispatch(
          setArtifactStatus({
            key: name,
            status: EArtifactStatus.INPROGRESS,
          }),
        );
      } else {
        console.log('Failed to start the transform process');
      }
    } catch (err) {
      console.log('Error:', err);
    } finally {
      setLoading(prev => ({ ...prev, [name]: false }));
    }
  };

  const onDownloadCode = async (name: string) => {
    try {
      setDownloading(prev => ({ ...prev, [name]: true }));
      await downloadCode(name);
    } finally {
      setDownloading(prev => ({ ...prev, [name]: false }));
    }
  };

  return (
    <div key={table.title}>
      <div
        className="pt-4 ml-0 flex items-center justify-between bg-gray-100 p-4 rounded-md shadow-md"
        onClick={toggleCollapse}
      >
        <h2 className="text-2xl font-semibold text-gray-800">{table.title}</h2>
        <button className="ml-2 text-gray-600 hover:text-gray-800 transition-colors duration-200">
          {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
        </button>
      </div>
      {showCancelConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-md shadow-md">
            <h2 className="text-xl font-semibold mb-4">Confirm Cancellation</h2>
            <p className="mb-4">
              Are you sure you want to cancel the transformation for{' '}
              {showCancelConfirmation}?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowCancelConfirmation(null)}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
              >
                No
              </button>
              <button
                onClick={() => handleCancel(showCancelConfirmation)}
                className="px-4 py-2 bg-red-500 text-white rounded-md"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
      {showBulkCancelConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-md shadow-md">
            <h2 className="text-xl font-semibold mb-4">
              Confirm Bulk Cancellation
            </h2>
            <p className="mb-4">
              Are you sure you want to cancel all artifact transformation which
              are in progress?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowBulkCancelConfirmation(false)}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
              >
                No
              </button>
              <button
                onClick={confirmBulkCancel}
                className="px-4 py-2 bg-red-500 text-white rounded-md"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
      {!isCollapsed && (
        <>
          <div className="flex items-center justify-between mb-4 mt-4 ml-6">
            <div className="flex flex-row gap-2">
              <input
                type="text"
                placeholder="Search by name"
                value={searchQuery}
                onChange={handleSearchChange}
                className="flex-1 rounded-md border border-input px-3 py-2 text-sm max-w-[20rem]"
              />
              <select
                value={filterOption}
                onChange={handleFilterChange}
                className="flex-none rounded-md border border-input px-3 py-2 text-sm"
                style={{ minWidth: '200px' }}
              >
                <option value="All">All</option>
                <option value="Transformation in progress">
                  Transformation in progress
                </option>
                <option value="Transformation finished">
                  Transformation finished
                </option>
                <option value="Transformation failed">
                  Transformation failed
                </option>
              </select>
            </div>
            <div className="flex flex-row gap-2 ml-auto mr-4">
              <button
                onClick={handleBulkTransform}
                className="px-3 py-2 bg-blue-500 text-white rounded-md text-sm"
              >
                Transform All Artifacts
              </button>

              <button
                onClick={handleBulkCancel}
                className="px-3 py-2 bg-red-500 text-white rounded-md text-sm"
              >
                Cancel All Transformations
              </button>
            </div>
          </div>
          <hr />
          <div className="p-6">
            <div className="relative w-full overflow-auto">
              <table className="w-full caption-bottom text-sm">
                <thead className="[&amp;_tr]:border-b ">
                  <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                    <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground w-[250px]">
                      Name
                    </th>
                    <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground w-[250px]">
                      Transform Rules
                    </th>
                    <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground w-[250px]">
                      Action
                    </th>
                    <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground w-[100px]">
                      Transform Program
                    </th>
                  </tr>
                </thead>
                <tbody className="[&amp;_tr:last-child]:border-0">
                  {table.rows
                    .filter(row => filterRows(row.name))
                    .map(row => (
                      <React.Fragment key={row.name}>
                        <tr
                          className={`border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted ${!['', EArtifactStatus.DEFAULT, undefined].includes(artifactStatus[row.name]) ? 'opacity-50' : ''}`}
                        >
                          <td className="p-4 align-middle font-medium">
                            {row.name}
                          </td>
                          <td className="p-4 align-middle w-[150px]">
                            <select
                              className="w-full rounded-md border border-input px-3 py-2 text-sm max-w-[12rem]"
                              disabled={
                                ![
                                  '',
                                  EArtifactStatus.DEFAULT,
                                  undefined,
                                ].includes(artifactStatus[row.name])
                              }
                              value={artifactTransformProfile[row.name] || ''}
                              onChange={e =>
                                handleTransformProfileChange(
                                  row.name,
                                  e.target.value,
                                )
                              }
                            >
                              {table.availableProfiles.map(profile => (
                                <option
                                  key={profile.name}
                                  value={profile.value}
                                >
                                  {profile.name}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td className="p-4 align-middle w-[250px]">
                            <button
                              onClick={() =>
                                showCallChainModal(row.name, table.title)
                              }
                              className="px-3 py-1 bg-blue-500 text-white rounded-md text-sm"
                              disabled={
                                ![
                                  '',
                                  EArtifactStatus.DEFAULT,
                                  undefined,
                                ].includes(artifactStatus[row.name])
                              }
                            >
                              View Callchain
                            </button>
                          </td>
                          <td className="p-4 align-middle w-[200px]">
                            {!loading[row.name] && (
                              <label className="switch ml-[2rem]">
                                <input
                                  type="checkbox"
                                  disabled={
                                    ![
                                      '',
                                      EArtifactStatus.DEFAULT,
                                      undefined,
                                    ].includes(artifactStatus[row.name])
                                  }
                                  checked={
                                    ![
                                      '',
                                      EArtifactStatus.DEFAULT,
                                      undefined,
                                    ].includes(artifactStatus[row.name])
                                  }
                                  onChange={() => handleToggleChange(row.name)}
                                />
                                <span className="slider round"></span>
                              </label>
                            )}

                            {loading[row.name] && (
                              <span className="ml-2">Loading...</span>
                            )}
                          </td>
                        </tr>
                        {!['', EArtifactStatus.DEFAULT, undefined].includes(
                          artifactStatus[row.name],
                        ) && (
                          <tr className="bg-gray-100">
                            <td colSpan={4} className="p-2">
                              <div className="progress-widget">
                                <div className="progress-bar">
                                  <div className="step">
                                    <div className="step-label">
                                      Code Generation
                                    </div>
                                    <div
                                      className="progress"
                                      style={{
                                        width: progress[row.name]
                                          ? `${Math.min(progress[row.name] * 4, 100)}%`
                                          : 0,
                                      }}
                                    ></div>
                                  </div>
                                  <div className="step">
                                    <div className="step-label">
                                      Code Compilation
                                    </div>
                                    <div
                                      className="progress"
                                      style={{
                                        width: progress[row.name]
                                          ? `${Math.min(Math.max(progress[row.name] - 25, 0) * 4, 100)}%`
                                          : 0,
                                      }}
                                    ></div>
                                  </div>
                                  <div className="step">
                                    <div className="step-label">
                                      Code Transformation
                                    </div>
                                    <div
                                      className="progress"
                                      style={{
                                        width: progress[row.name]
                                          ? `${Math.min(Math.max(progress[row.name] - 50, 0) * 4, 100)}%`
                                          : 0,
                                      }}
                                    ></div>
                                  </div>
                                  <div className="step">
                                    <div className="step-label">Packaging</div>
                                    <div
                                      className="progress"
                                      style={{
                                        width: progress[row.name]
                                          ? `${Math.min(Math.max(progress[row.name] - 75, 0) * 4, 100)}%`
                                          : 0,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                                <div className="progress-info pt-2">
                                  <span>
                                    {progress[row.name] || 0}% Complete
                                  </span>

                                  <div className="flex flex-row items-center gap-2">
                                    <button
                                      className="flex items-center px-3 py-1 bg-red-500 text-white rounded-md text-sm hover:bg-red-600 transition-colors duration-200"
                                      onClick={() => confirmCancel(row.name)}
                                    >
                                      {loading[row.name]
                                        ? 'Loading...'
                                        : 'Cancel'}
                                    </button>
                                    {/* {loading[row.name] && (
                                      <span>Loading...</span>
                                    )} */}
                                    {artifactStatus[row.name] ===
                                      EArtifactStatus.SUCCESS && (
                                      <button
                                        disabled={downloading[row.name]}
                                        className="flex items-center px-3 py-1 bg-blue-500 text-white rounded-md text-sm hover:bg-blue-600 transition-colors duration-200"
                                        onClick={() => onDownloadCode(row.name)}
                                      >
                                        <FaDownload className="mr-2" />
                                        {downloading[row.name]
                                          ? 'Downloading...'
                                          : 'Download Code'}
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SubTable;
