import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, Modal, Select } from "antd";
import { VscRefresh } from "react-icons/vsc";
import { FaBitbucket, FaCheck, FaChevronDown, FaChevronUp, FaDownload, FaGithub, FaGitlab, FaTimes } from "react-icons/fa";
import { downloadCode } from "../transformation-page/transformation.service";
import "./deploy-chevron.css"
import ModalDeploy from "./modal-deploy/modal-deploy";
import DeployProgress from "./modal-deploy/deploy-progress-table";

const DeployChevron = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isProgress, setIsProgress] = useState(false);
    const [isRepoModalVisible, setRepoModalVisible] = useState(false);
    const [selectedPlatform, setSelectedPlatform] = useState('');
    const [isFormSubmit, setIsFormSubmit] = useState(false)
    const [data, setData] = useState([
        {
            jobName: 'CFBOOKSJ',
            executionType: false,
            validated: 'PENDING',
            expanded: true,
            progress: 0,
            success: false,
            completionDate: '',
        },
    ]);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const formSubmitted = () => {
        setIsFormSubmit(true);
        setRepoModalVisible(false);
        setIsModalVisible(false);
        startProgress();
    }

    const startProgress = () => {
        setIsProgress(true)
    }

    const handleRepoModalOpen = (platform: string) => {
        setSelectedPlatform(platform);
        setRepoModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleExpandToggle = (index) => {
        const updatedData = [...data];
        updatedData[index].expanded = !updatedData[index].expanded;
        setData(updatedData);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const platformTitles = {
        github: 'GitHub',
        gitlab: 'GitLab',
        bitbucket: 'Bitbucket'
    };

    const platformIcons = {
        github: <FaGithub />,
        gitlab: <FaGitlab />,
        bitbucket: <FaBitbucket />
    };

    const getDeploymentUrl = (platform, repoUrl) => {
        const platformUrls = {
            github: `https://github.com/${repoUrl}`,
            gitlab: `https://gitlab.com/${repoUrl}`,
            bitbucket: `https://bitbucket.org/${repoUrl}`,
        };
        return platformUrls[platform];
    };

    return (
        <div>
            <div
                className="rounded-lg border bg-card text-card-foreground shadow-sm"
                data-v0-t="card"
            >
                <div className="flex flex-col space-y-4 p-6 bg-[#0a61dc] text-white">
                    <div className='flex flex-row'>
                        <h2 className="text-2xl font-bold text-white">Jobs ready for Deployment</h2>
                    </div>
                    <p className="text-sm text-white">Deployment allow you to push your latest code changes to your selected platform, such as GitHub, GitLab, or Bitbucket. Additionally, you have the option to upload a script file that will be pushed along with your code. Once initiated, the process we will establish connection with the platform of your choice, commit the changes, and deploy the code to the respective platform. You can track progress in real-time, and upon successful deployment, view the code or logs on your platform of choice.</p>
                </div>
                <hr />
                <div className="p-6">
                    <div className="relative w-full overflow-auto">
                        <table className="w-full caption-bottom text-sm">
                            <thead className="[&amp;_tr]:border-b ">
                                <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                                    {isProgress && (
                                        <th className="h-12 px-4 text-left font-medium text-muted-foreground w-[200px]">
                                            Expand/Collapse to view Deploy status
                                        </th>
                                    )}
                                    <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground w-[150px]">
                                        Job Name
                                    </th>
                                    <th className="h-12 px-4 text-center align-middle font-medium text-muted-foreground w-[250px]">
                                        Deployment Status
                                    </th>
                                    <th className="h-12 px-4 text-center align-middle font-medium text-muted-foreground w-[250px]">
                                        Latest Deployment
                                    </th>
                                    <th className="h-12 px-4 text-right align-middle font-medium text-muted-foreground w-[250px]">
                                        Deploy
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="[&amp;_tr:last-child]:border-0">
                                {data.map((job, index) => (
                                    <React.Fragment key={index}>
                                        <tr
                                            className={`border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted`}
                                        >
                                            {isProgress && (
                                                <td className="p-4 align-middle">
                                                    {job.expanded ? (
                                                        <FaChevronUp
                                                            className="cursor-pointer"
                                                            onClick={() => handleExpandToggle(index)}
                                                            title='Click to hide input and output files'
                                                        />
                                                    ) : (
                                                        <FaChevronDown
                                                            className="cursor-pointer"
                                                            onClick={() => handleExpandToggle(index)}
                                                            title='Click to view input and output files'
                                                        />
                                                    )}
                                                </td>
                                            )}
                                            <td className="p-4 align-middle text-left font-medium">
                                                {job.jobName}
                                            </td>
                                            <td className="p-4 align-middle w-[250px] text-center text-green-400 font-semibold">
                                                {job.success ? (
                                                    <FaCheck className="text-green-500 inline-block" title='Success' />
                                                ) : (
                                                    job.validated === 'FAILED' ? (
                                                        <FaTimes className="text-red-500 inline-block" title='Failed' />
                                                    ) : <div className='text-sm rounded-full bg-yellow-500 w-3.5 h-3.5 inline-block' title='Pending...'></div>
                                                )}
                                            </td>
                                            <td className="p-4 align-middle w-[250px] text-center text-green-400 font-semibold">
                                                {job.completionDate ? (
                                                    <div className="text-gray-500 text-sm font-semibold">
                                                        {job.completionDate}
                                                    </div>
                                                ) : (
                                                    <div className="text-gray-400 text-sm font-semibold">
                                                        ----
                                                    </div>
                                                )}
                                            </td>
                                            <td className="p-4 align-middle w-[250px] text-right">
                                                {job.success ? (
                                                    <div className="flex gap-2 items-center justify-end">
                                                        <a
                                                            // href={getDeploymentUrl(selectedPlatform, job.repoUrl)}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="flex items-center cursor-pointer text-sm bg-[#2ea44f] text-white px-4 py-2 rounded-md border border-transparent shadow-sm hover:bg-[#2c974b] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2ea44f] transition-colors duration-200"
                                                        >
                                                            <span className="mr-2">View in {platformTitles[selectedPlatform]}</span>
                                                            {platformIcons[selectedPlatform]}
                                                        </a>
                                                    </div>
                                                ) : (
                                                    <button className={`bg-cta text-white p-2 rounded-md ${job.progress > 0 ? 'opacity-50' : ''}`} onClick={showModal} disabled={job.progress > 0} >Deploy Job</button>
                                                )}
                                            </td>
                                        </tr>
                                        {isFormSubmit && job.expanded && (
                                            <tr className="bg-white">
                                                <td className="p-4 bg-gray-100" colSpan={5}>
                                                    <div className="p-6 border bg-white border-blue-200 shadow-lg rounded-lg">
                                                        {isProgress && (
                                                            <DeployProgress
                                                                selectedPlatform={selectedPlatform}
                                                                job={job}
                                                                setData={setData}
                                                            />
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Modal
                title={
                    <div className="text-center font-semibold text-lg">
                        Deploy Job
                    </div>
                }
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <hr className="my-4 border-gray-300" />
                <div className="space-y-6">
                    {/* GitHub */}
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <FaGithub className="text-2xl text-gray-600 mr-3" />
                            <span className="font-medium text-gray-800">Deploy to GitHub</span>
                        </div>
                        <button onClick={() => handleRepoModalOpen('github')} className="bg-cta text-white p-2 rounded-md text-sm">
                            Create Repo
                        </button>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <FaBitbucket className="text-2xl text-gray-600 mr-3" />
                            <span className="font-medium text-gray-800">Deploy to Bitbucket</span>
                        </div>
                        <button onClick={() => handleRepoModalOpen('bitbucket')} className="bg-cta text-white p-2 rounded-md text-sm">
                            Create Repo
                        </button>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <FaGitlab className="text-2xl text-gray-600 mr-3" />
                            <span className="font-medium text-gray-800">Deploy to GitLab</span>
                        </div>
                        <button onClick={() => handleRepoModalOpen('gitlab')} className="bg-cta text-white p-2 rounded-md text-sm">
                            Create Repo
                        </button>
                    </div>
                </div>
            </Modal>
            <ModalDeploy
                isRepoModalVisible={isRepoModalVisible}
                setRepoModalVisible={setRepoModalVisible}
                selectedPlatform={selectedPlatform}
                formSubmitted={formSubmitted}
            />
        </div >
    )
}
export default DeployChevron;