import React from 'react';

interface MissingUploadBtnProps {
  handleFileUpload: (event: React.ChangeEvent<HTMLInputElement>, itemKey?: number) => void;
  item?: { key: number; uploaded?: string };
  accept?: string;
  buttonStyle?: React.CSSProperties;
}

const MissingUploadBtn: React.FC<MissingUploadBtnProps> = ({ handleFileUpload, item, accept, buttonStyle }) => {
  const itemKey = item?.key;
  return (
    <div>
      <label htmlFor={`fileInput-${itemKey ?? 'general'}`} style={buttonStyle} className="md:hover:bg-offsetPlus text-textOff dark:text-textOffDark md:hover:text-textMain dark:md:hover:bg-offsetPlusDark dark:md:hover:text-textMainDark font-sans focus:outline-none outline-none outline-transparent transition duration-300 ease-in-out font-sans select-none items-center relative group/button justify-center text-center items-center rounded-full cursor-point active:scale-95 origin-center whitespace-nowrap inline-flex text-sm px-sm font-medium h-8 cursor-pointer">
        <div className="flex items-center leading-none justify-center gap-xs bg-cta text-white hover:shadow-md py-2 rounded-full px-4" style={buttonStyle}>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="circle-plus"
            className="svg-inline--fa fa-circle-plus fa-fw fa-1x"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            fill="black"
            width={16}
            height={16}
          >
            <path
              fill="currentColor"
              d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344c0 13.3 10.7 24 24 24s24-10.7 24-24V280h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H280V168c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H168c-13.3 0-24 10.7-24 24s10.7 24 24 24h64v64z"
            ></path>
          </svg>
          <div className="text-align-center relative ml-[4px] text-[14px] text-white font-semibold" >
            Upload
          </div>
          <input
            type="file"
            id={`fileInput-${itemKey ?? 'general'}`}
            style={{ display: 'none' }}
            onChange={(event) => handleFileUpload(event, itemKey)}
            accept={accept}
          />
        </div>
      </label>
    </div>
  );
};

export default MissingUploadBtn;