import React, { useEffect, useState } from 'react';
import { useCallChainUtils } from '../../../hooks/useCallChainUtils';
import { appTheme } from '../../../styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import {
  setActiveModal,
  setCurrentIntent,
  setIsCallChainLoaded,
} from '../../../redux/app-global';
import {
  EAppIntent,
  EAppModal,
  ILicenseDetails,
} from '../../../redux/app-global/app-global.types';
import { SharedService } from '../../../services/shared-service';
import axios from 'axios';
import { externalConfig } from '../../../utils/misc.utils';
import useTransformation from '../transformation-page/useTransformation';
import { RootState } from '../../../redux/store';
import { helpTexts } from '../../../helpTexts';
import HelpTextCallout from '../../../components/help-text-callout/help-text-callout';
import { createNoSubstitutionTemplateLiteral } from 'typescript';

interface CallChainProps {
  programDetailsMap: any;
  scrollTable: (direction: string, id: string) => void;
  license: ILicenseDetails | null;
}

const CallChain: React.FC<CallChainProps> = ({
  programDetailsMap,
  scrollTable,
  license,
}) => {
  const { loading, selectedProgramId, getAllSvgStrings, jclArtifactIds } =
    useCallChainUtils();
  const [programMap, setProgramMap] = useState<{ [key: string]: string }>({});
  const [jclMap, setJclMap] = useState<{ [key: string]: string }>({});
  const [jclMissingCount, setJclMissingCount] = useState(0);
  const [notJclMissingCount, setJclNotMissingCount] = useState(0);
  const [programMissingCount, setProgramMissingCount] = useState(0);
  const [notProgramMissingCount, setProgramNotMissingCount] = useState(0);
  const [missingArtifacts, setMissingArtifacts] = useState(new Set());
  const nextMissingPage = useSelector(
    (state: RootState) => state.appGlobal.nextMissingPage,
  );
  const missingPages = useSelector(
    (state: RootState) => state.appGlobal.missingPages,
  );
  // const { driverPrograms, otherPrograms, availableProfiles } =
  //   useTransformation();
  const pageHelpTexts = helpTexts[0].REPORT;

  console.log('JCL length:', jclArtifactIds?.length);

  useEffect(() => {
    const checkMissingArtifacts = () => {
      const missingArtifacts = new Set();
      if (missingPages.length > 0) {
        Object.values(missingPages[0].data).forEach(itemArray => {
          (itemArray as any).forEach(item => {
            if (item.programName) {
              missingArtifacts.add(item.programName);
            }
            if (item.referredIn && item.referredIn.jcl) {
              item.referredIn.jcl.forEach(jclName => {
                missingArtifacts.add(jclName);
              });
            }
          });
        });
        setMissingArtifacts(missingArtifacts);
      }
    };
    checkMissingArtifacts();
  }, [missingPages]);
  const dispatch = useDispatch();
  let scrollInterval;
  const startScroll = (direction, id) => {
    scrollTable(direction, id);
    scrollInterval = setInterval(() => {
      scrollTable(direction, id);
    }, 100);
  };
  const stopScroll = () => {
    clearInterval(scrollInterval);
  };
  useEffect(() => {
    let missingJcl = 0;
    let notMissingJcl = 0;
    let missingProgram = 0;
    let notMissingProgram = 0;
    jclArtifactIds.forEach(name => {
      if (missingArtifacts.has(name)) {
        missingJcl++;
      } else {
        notMissingJcl++;
      }
    });

    Object.keys(programMap).forEach(key => {
      if (missingArtifacts.has(key)) {
        missingProgram++;
      } else {
        notMissingProgram++;
      }
    });
    setJclMissingCount(missingJcl);
    setJclNotMissingCount(notMissingJcl);
    setProgramMissingCount(missingProgram);
    setProgramNotMissingCount(notMissingProgram);
  }, [jclArtifactIds, missingArtifacts, programMap]);
  document.addEventListener('mouseup', stopScroll);
  document.addEventListener('mouseleave', stopScroll);
  useEffect(() => {
    setTimeout(async () => {
      dispatch(setIsCallChainLoaded(!loading));
      if (!loading && selectedProgramId) {
        const programToSvgMap = await getAllSvgStrings();
        const newProgramMap = {};
        const newJclMap = {};

        Object.entries(programToSvgMap).forEach(([key, value]) => {
          if (jclArtifactIds.includes(key)) {
            newJclMap[key] = value;
          } else {
            newProgramMap[key] = value;
          }
        });

        setProgramMap(newProgramMap);
        setJclMap(newJclMap);
      }
    }, 0);
  }, [loading, selectedProgramId, jclArtifactIds, dispatch]);
  const handleViewDocumentation = async (
    key: string,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    // const response = await fetch('/ELBAIFUC.html');
    // if (!response.ok) {
    //   throw new Error('Network response was not ok');
    // }xios.get(`${externalConfig?.scannerUrl || process.env.REACT_APP_API_URL}/scan/smart-doc/${key}`)
    axios
      .get(
        `${externalConfig?.scannerUrl || process.env.REACT_APP_API_URL}/scan/smart-doc/${key}`,
      )
      .then(res => {
        SharedService.documentationHtmlContent = res.data;
        SharedService.documentationProgramName = key;
        dispatch(setActiveModal(EAppModal.DOCUMENTATION_MODAL));
      })
      .catch(err => {
        console.error('Unable to fetch documentation: ', err);
      });
    // const htmlContent = await response.text();
    //
  };
  const resolveMissingArtifacts = () => {
    dispatch(setCurrentIntent(nextMissingPage as any));
  };
  const ignoreMissingArtifacts = () => {};
  const toggleGraphDisplay = (
    key: string,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const graphElement = document.getElementById(`graph-row-${key}`);
    const button = event.currentTarget;
    if (graphElement) {
      if (graphElement.style.display === 'none') {
        graphElement.style.display = 'table-row';
        button.textContent = 'Hide Graph';
      } else {
        graphElement.style.display = 'none';
        button.textContent = 'View Graph';
      }
    }
    const jclScrollCheck = document.getElementById(`jclTable-${key}`);
    const programScrollCheck = document.getElementById(`programTable-${key}`);
    const leftJclScrollCheckBtn = document.getElementById(
      `leftjclTable-${key}Btn`,
    );
    const rightJclScrollCheckBtn = document.getElementById(
      `rightjclTable-${key}Btn`,
    );
    const leftProgramScrollCheckBtn = document.getElementById(
      `leftprogramTable-${key}Btn`,
    );
    const rightProgramScrollCheckBtn = document.getElementById(
      `rightprogramTable-${key}Btn`,
    );
    if (jclScrollCheck) {
      if (
        (jclScrollCheck as any).scrollWidth <=
        (jclScrollCheck as any).clientWidth
      ) {
        (leftJclScrollCheckBtn as any).style.visibility = 'hidden';
        (rightJclScrollCheckBtn as any).style.visibility = 'hidden';
      }
    }
    if (programScrollCheck) {
      if (
        (programScrollCheck as any).scrollWidth <=
        (programScrollCheck as any).clientWidth
      ) {
        (leftProgramScrollCheckBtn as any).style.visibility = 'hidden';
        (rightProgramScrollCheckBtn as any).style.visibility = 'hidden';
      }
    }
  };
  return (
    <div className="overflow-auto">
      <div className="px-6">
        {jclArtifactIds.length > 0 && (
          <div>
            <div className="flex items-center justify-center py-8">
              <h2 className="text-l font-bold my-4 text-center flex-grow">
                JCL CALL CHAIN GRAPH
              </h2>
              <div className="hide-in-report">
                <HelpTextCallout calloutText={pageHelpTexts[0].description} />
              </div>
              <div
                id="view-in-canvas"
                className="mr-4 view-in-canvas hide-in-report"
              >
                <p
                  onClick={() => {
                    dispatch(setCurrentIntent(EAppIntent.CALLCHAIN));
                  }}
                  className="text-sm px-4 py-[2px] cursor-pointer bg-yellowBase rounded-full shadow-sm hover:shadow-md hover:scale-105"
                >
                  View in Canvas
                </p>
              </div>
            </div>
            <p className="mb-8 ">
              The JCL Call Chain Graph visualizes the hierarchical structure of
              JCL within a system. It starts from the JCL and shows its steps,
              depicting how these steps call other programs. This graphical
              representation offers a clear overview of the JCL&apos;s structure
              and the flow of execution within the application.
            </p>
            <div className="overflow-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 text-left text-sm font-bold text-gray-500 uppercase tracking-wider">
                      JCL
                    </th>
                    <th className="hide-in-report px-6 py-3 text-center text-sm font-bold text-gray-500 uppercase tracking-wider">
                      Transformation Status
                      <div className="text-xs mt-2">
                        (
                        <span className="text-green-500">
                          {notJclMissingCount} ready{' '}
                        </span>
                        ,{' '}
                        <span className="text-red-500">
                          {jclMissingCount} need attention
                        </span>
                        )
                      </div>
                    </th>
                    {/* <th className="hide-in-report px-6 py-3 text-center text-sm font-bold text-gray-500 uppercase tracking-wider">
                      Ignore Transformation
                    </th> */}
                    <th className="px-6 py-3 text-right text-sm font-bold text-gray-500 uppercase tracking-wider">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {Object.entries(jclMap)
                    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                    .map(([key, value], index) => (
                      <React.Fragment key={key}>
                        <tr>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {key}
                          </td>
                          <td className="hide-in-report px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                            {!missingArtifacts.has(key) ? (
                              <div className="text-green-600">
                                Ready for Transformation
                              </div>
                            ) : (
                              <div className="flex flex-row justify-center">
                                <div className="hide-in-report">
                                  <HelpTextCallout
                                    calloutText={pageHelpTexts[2].description}
                                  />
                                </div>
                                <button
                                  className="text-red-500 border px-2 py-2 font-semibold border-red-500 rounded-lg"
                                  onClick={resolveMissingArtifacts}
                                >
                                  Resolve Missing Artifacts
                                </button>
                              </div>
                            )}
                          </td>
                          {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{missingArtifacts.has(key) &&  (
                                <button className='text-orange-500 border px-2 py-2 font-semibold rounded-lg' onClick={resolveMissingArtifacts}>Ignore Missing Artifacts</button>
                              )}
                          </td> */}
                          <td className="flex flex-row justify-end px-2 py-4 whitespace-nowrap text-sm text-green-700 text-right">
                            <div className="hide-in-report">
                              <HelpTextCallout
                                calloutText={pageHelpTexts[1].description}
                              />
                            </div>
                            <button
                              data-key={key}
                              onClick={e => toggleGraphDisplay(key, e)}
                              className="toggle-graph px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                            >
                              View Graph
                            </button>
                          </td>
                        </tr>
                        <tr id={`graph-row-${key}`} style={{ display: 'none' }}>
                          <td colSpan={2} className="max-w-[40rem]">
                            <div className="relative">
                              <>
                                <div
                                  className="absolute left-0 top-1/2 transform -translate-y-1/2 h-8 w-8 flex justify-center items-center rounded-full z-10 bg-blue-600"
                                  style={{ visibility: 'hidden' }}
                                  id={`leftjclTable-${key}Btn`}
                                >
                                  <button
                                    className="scroll-table text-white font-bold"
                                    table-attribute={`jclTable-${key}`}
                                    direction-btn={'left'}
                                    onMouseDown={() =>
                                      startScroll('left', `jclTable-${key}`)
                                    }
                                    onMouseUp={() => stopScroll()}
                                    onMouseLeave={() => stopScroll()}
                                  >
                                    &lt;
                                  </button>
                                </div>
                                <div
                                  className="absolute right-0 top-1/2 transform -translate-y-1/2 h-8 w-8 bg-blue-600 flex justify-center items-center rounded-full z-10"
                                  id={`rightjclTable-${key}Btn`}
                                >
                                  <button
                                    className="scroll-table text-white font-bold"
                                    table-attribute={`jclTable-${key}`}
                                    direction-btn={'right'}
                                    onMouseDown={() =>
                                      startScroll('right', `jclTable-${key}`)
                                    }
                                    onMouseUp={() => stopScroll()}
                                    onMouseLeave={() => stopScroll()}
                                  >
                                    &gt;
                                  </button>
                                </div>
                              </>
                              <div
                                className="max-h-[40rem] p-4 rounded-md flex flex-col mb-4 -mt-[2px]"
                                id={`jclTable-${key}`}
                                style={{
                                  border: `1px solid ${appTheme.colors.grey20}`,
                                  overflowX: 'hidden',
                                  maxWidth: '95vw',
                                  marginTop: '-2px',
                                }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{ __html: value }}
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <hr style={{ marginTop: '2rem', marginBottom: '2rem' }} />
        {Object.entries(programMap).length > 0 && (
          <div>
            <div className="flex items-center justify-center py-8">
              <h2 className="text-l font-bold my-4 text-center flex-grow">
                PROGRAM CALL CHAIN GRAPH
              </h2>
              <div className="hide-in-report">
                <HelpTextCallout calloutText={pageHelpTexts[0].description} />
              </div>
              <div
                id="view-in-canvas"
                className="mr-4 view-in-canvas hide-in-report"
              >
                <p
                  onClick={() => {
                    dispatch(setCurrentIntent(EAppIntent.CALLCHAIN));
                  }}
                  className="text-sm px-4 py-[2px] cursor-pointer bg-yellowBase rounded-full shadow-sm hover:shadow-md hover:scale-105"
                >
                  View in Canvas
                </p>
              </div>
            </div>
            <p className="mb-8 ">
              The Program Call Chain Graph visualizes the hierarchy of programs
              within your system, starting from the root program and depicting
              how it calls its subprograms, and how those subprograms further
              call other subprograms. This graph provides a clear overview of
              the program structure and the flow of execution within your
              application.
            </p>
            <div>
              <div className="overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 text-left text-sm font-bold text-gray-500 uppercase tracking-wider">
                        Program
                      </th>
                      <th className="px-6 py-3 text-center text-sm font-bold text-gray-500 uppercase tracking-wider">
                        Language
                      </th>
                      <th className="px-6 py-3 text-center text-sm font-bold text-gray-500 uppercase tracking-wider">
                        CICS
                      </th>
                      <th className="px-6 py-3 text-center text-sm font-bold text-gray-500 uppercase tracking-wider">
                        MQ
                      </th>
                      <th className="px-6 py-3 text-center text-sm font-bold text-gray-500 uppercase tracking-wider">
                        DB2
                      </th>
                      <th className="hide-in-report px-6 py-3 text-center text-sm font-bold text-gray-500 uppercase tracking-wider">
                        Transformation status
                        <div className="hide-in-report text-xs mt-2">
                          (
                          <span className="text-green-500">
                            {notProgramMissingCount} ready{' '}
                          </span>
                          ,{' '}
                          <span className="text-red-500">
                            {programMissingCount} need attention
                          </span>
                          )
                        </div>
                      </th>
                      {/* <th className="px-6 py-3 text-center text-sm font-bold text-gray-500 uppercase tracking-wider">
                        Ignore Transformation
                      </th> */}
                      <th className="px-6 py-3 text-right text-sm font-bold text-gray-500 uppercase tracking-wider">
                        Action
                      </th>
                      {license?.licenseType === 'ONPREM' && (
                        <th
                          id="hideInOffline"
                          className="px-6 py-3 text-right text-sm font-bold text-gray-500 uppercase tracking-wider"
                        >
                          SmartDoc
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {Object.entries(programMap)
                      .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                      .map(([key, value], index) => (
                        <React.Fragment key={key}>
                          <tr key={key}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {key}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                              {programDetailsMap[key]?.language}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-green-700 text-center">
                              {programDetailsMap[key]?.isCicsProgram && '✓'}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-green-700 text-center">
                              {programDetailsMap[key]?.isMqProgram && '✓'}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-green-700 text-center">
                              {programDetailsMap[key]?.isDb2Program && '✓'}
                            </td>
                            <td className="hide-in-report px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                              {!missingArtifacts.has(key) ? (
                                <div className="text-green-600">
                                  Ready for Transformation
                                </div>
                              ) : (
                                <div>
                                  <div className="hide-in-report">
                                    <HelpTextCallout
                                      calloutText={pageHelpTexts[2].description}
                                    />
                                  </div>
                                  <button
                                    className="text-red-500 border px-2 py-2 font-semibold border-red-500 rounded-lg"
                                    onClick={resolveMissingArtifacts}
                                  >
                                    Resolve Missing Artifacts
                                  </button>
                                </div>
                              )}
                            </td>
                            {/* <td className="hide-in-report px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{missingArtifacts.has(key) &&  (
                                <button className='text-orange-500 border px-2 py-2 font-semibold rounded-lg' onClick={resolveMissingArtifacts}>Ignore Missing Artifacts</button>
                              )}
                          </td> */}
                            <td className="flex flex-row justify-end px-2 py-4 whitespace-nowrap text-sm text-green-700 text-right">
                              <button
                                data-key={key}
                                onClick={e => toggleGraphDisplay(key, e)}
                                className="toggle-graph px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                              >
                                View Graph
                              </button>
                            </td>
                            {license?.licenseType === 'ONPREM' && (
                              <td
                                id="hideInOffline"
                                className="px-6 py-4 whitespace-nowrap text-sm text-green-700 text-right"
                              >
                                <button
                                  className="toggle-graph px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                                  onClick={e => handleViewDocumentation(key, e)}
                                >
                                  View SmartDoc
                                </button>
                              </td>
                            )}
                          </tr>
                          <tr
                            id={`graph-row-${key}`}
                            style={{ display: 'none' }}
                          >
                            <td
                              colSpan={7}
                              className="max-w-[40rem] overflow-hidden"
                            >
                              <div className="relative">
                                <>
                                  <div
                                    className="absolute left-0 top-1/2 transform -translate-y-1/2 h-8 w-8 flex justify-center items-center rounded-full z-10 bg-blue-600"
                                    style={{ visibility: 'hidden' }}
                                    id={`leftprogramTable-${key}Btn`}
                                  >
                                    <button
                                      className="scroll-table text-white font-bold"
                                      onMouseDown={() =>
                                        startScroll(
                                          'left',
                                          `programTable-${key}`,
                                        )
                                      }
                                      onMouseUp={() => stopScroll()}
                                      onMouseLeave={() => stopScroll()}
                                      table-attribute={`programTable-${key}`}
                                      direction-btn={'left'}
                                    >
                                      &lt;
                                    </button>
                                  </div>
                                  <div
                                    className="absolute right-0 top-1/2 transform -translate-y-1/2 h-8 w-8 bg-blue-600 flex justify-center items-center rounded-full z-10"
                                    id={`rightprogramTable-${key}Btn`}
                                  >
                                    <button
                                      className="scroll-table text-white font-bold"
                                      onMouseDown={() =>
                                        startScroll(
                                          'right',
                                          `programTable-${key}`,
                                        )
                                      }
                                      onMouseUp={() => stopScroll()}
                                      onMouseLeave={() => stopScroll()}
                                      table-attribute={`programTable-${key}`}
                                      direction-btn={'right'}
                                    >
                                      &gt;
                                    </button>
                                  </div>
                                </>
                                <div
                                  className="max-h-[40rem] p-4 rounded-md flex flex-col mb-4"
                                  id={`programTable-${key}`}
                                  style={{
                                    border: `1px solid ${appTheme.colors.grey20}`,
                                    overflowX: 'hidden',
                                    maxWidth: '95vw',
                                    marginTop: '-2px',
                                  }}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{ __html: value }}
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CallChain;
