import React from 'react';
import { Switch, Tooltip } from 'antd';
import { FaCheck, FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';


interface Job {
    jobName: string;
    executionType: boolean; 
    validated: string; 
    expanded: boolean;
}

interface TableProps {
    data: Job[];
    onSwitchChange: (index: number) => void; 
    onExpandToggle: (index: number) => void; 
}

const Table: React.FC<TableProps> = ({ data, onSwitchChange, onExpandToggle }) => {

    const extractFile = async (jobName) => {
        try {
            const response = await fetch('download.jcl');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const fileContent = await response.text();
            const blob = new Blob([fileContent], { type: 'text/plain' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'EXPORT.VSAM.FB.jcl';
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link)
        } catch {
            console.error("error")
        }
    }
    return (
        <table className="w-full caption-bottom text-sm">
            <thead>
                <tr className="border-b">
                    <th className="h-12 px-4 text-left font-medium text-muted-foreground w-[150px]">Expand/Collapse to view I/O Files</th>
                    <th className="h-12 px-4 text-left font-medium text-muted-foreground w-[150px]">Job Name</th>
                    <th className="h-12 px-4 text-center font-medium text-muted-foreground w-[100px]">Execution Type</th>
                    <th className="h-12 px-4 text-center font-medium text-muted-foreground w-[100px]">Validation</th>
                    <th className="h-12 px-4 text-center font-medium text-muted-foreground w-[100px]">Export JCL</th>
                </tr>
            </thead>
            <tbody>
                {data.map((job, index) => (
                    <React.Fragment key={index}>
                        <tr className="border-b hover:bg-muted/50">
                            <td className="p-4 align-middle">
                                {job.expanded ? (
                                    <FaChevronUp
                                        className="cursor-pointer"
                                        onClick={() => onExpandToggle(index)}
                                        title='Click to hide input and output files'
                                    />
                                ) : (
                                    <FaChevronDown
                                        className="cursor-pointer"
                                        onClick={() => onExpandToggle(index)}
                                        title='Click to view input and output files'
                                    />
                                )}
                            </td>
                            <td className="p-4 align-middle font-medium">{job.jobName}</td>
                            <td className="p-4 align-middle">
                                <div className="flex items-center justify-center gap-3 w-full">
                                    <span className="text-sm text-muted-foreground">Local Run</span>
                                    <Switch
                                        checked={job.executionType}
                                        onChange={() => onSwitchChange(index)}
                                    />
                                    <span className="text-sm text-muted-foreground">Server Run</span>
                                </div>
                            </td>
                            <td className="p-4 align-middle text-center">
                                {job.validated === 'SUCCESS' ? (
                                    <FaCheck className="text-green-500 inline-block" title='Success' />
                                ) : (
                                    job.validated === 'FAILED' ? (
                                        <FaTimes className="text-red-500 inline-block" title='Failed' />
                                    ) : <div className='text-sm rounded-full bg-yellow-500 w-3.5 h-3.5 inline-block' title='Pending...'></div>
                                )}
                            </td>
                            <td className="p-4 align-middle text-center">
                                <button
                                    className='bg-cta text-white p-2 rounded-md inline-flex justify-center items-center gap-2 text-sm'
                                    onClick={extractFile}
                                >
                                    Export JCL
                                </button>
                            </td>
                        </tr>
                    </React.Fragment>
                ))}
            </tbody>
        </table>
    );
};

export default Table;
