import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
import { FaDownload, FaSave, FaSpinner, FaUpload } from 'react-icons/fa';
import { downloadExtractJcl, externalConfig, isValidDataset } from '../../../utils/misc.utils';
import axios from 'axios';
import { setActiveModal, setCurrentIntent, setNextMissingPage, setNextMissingPageTest } from '../../../redux/app-global';
import { EAppIntent, EAppModal, TableData } from '../../../redux/app-global/app-global.types';
import ReusableTable from '../../../components/missing-artifacts-table/missing-artifacts-table';
import {
    PrimaryButton,
    TooltipHost,
    Toggle
} from '@fluentui/react';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import StepperComponent from '../../../components/stepper-component/stepper-component';
import Dropdown from '../../../components/custom-dropdown/custom-dropdown';
import MissingUploadList, { UploadMissingListRef } from '../../../components/missing-artifact-upload/missing-artifact-upload';
import MissingUploadBtn from '../../../components/missing-upload-button/missing-upload-button';
import { VALID_PROGRAM_FILE_TYPES } from '../missing-program/missing-program-types';
import { UploadFile } from '../../../components/upload-list/upload-list.types';
import { ExtractJclRequest, ExtractType } from '../extract-artifact/extract-artifact.model';
import { helpTexts } from '../../../helpTexts';
import HelpTextCallout from '../../../components/help-text-callout/help-text-callout';


interface Artifact {
    variableName: string;
    programName: string;
    value: string;
}

const ResolveDynamic = () => {
    const [artifacts, setArtifacts] = useState<Artifact[]>([]);
    const [error, setError] = useState("");
    const [file, setFile] = useState<File | null>(null);
    const [tableValue, setTableValue] = useState<Artifact[]>([]);
    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const [missingPrograms, setMissingPrograms] = useState<string[]>([]);
    const missingPages = useSelector((state: RootState) => state.appGlobal.missingPages);
    const dynamicCallData = useSelector((state: RootState) => state.appGlobal.dynamicCallData);
    const [files, setFiles] = useState<Record<string, UploadFile>>({});
    const dispatch = useDispatch();
    const pageHelpText = helpTexts[0].RESOLVE_DYNAMIC_CALLS;
    useEffect(() => {
        const fetchData = async () => {
            const apiUrl = `${externalConfig?.scannerUrl || process.env.REACT_APP_REPORT_API_URL}/missing-artifact/DYNAMIC_CALL`;

            try {
                const response = await axios.get(apiUrl);
                const data = response.data?.data ?? response.data ?? [];
                const transformedData: TableData[] = data.flatMap((dataItem: any) =>
                    dataItem.missingData?.map((item: any, index: number) => ({
                        key: index,
                        selectedOption: 'Active',
                        programName: item.programName,
                        requiredBy: item.variableName,
                        uploaded: null,
                    }))
                );
                setData(transformedData);
                if (Array.isArray(data) && data.length > 0) {
                    const [firstItem] = data;
                    setTableValue(firstItem.missingData || []);
                    setMissingPrograms(firstItem.programList || []);
                    setLoading(false);
                } else {
                    setTableValue([]);
                    setMissingPrograms([]);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('An error occurred while fetching data.');
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const index = parseInt(e.target.dataset.index, 10);

        setTableValue(prevState => {
            const newState = [...prevState];
            newState[index] = { ...newState[index], value: value };
            return newState;
        });
    };
    const changeDisplay = (
        ev: React.MouseEvent<HTMLElement>,
        checked?: boolean,
    ) => {
        setToggleValue(checked);
    };
    const handleOptionSelects = (variableName: string, option: string) => {
        setTableValue(prevState =>
            prevState.map(artifact =>
                artifact.variableName === variableName
                    ? { ...artifact, value: option }
                    : artifact
            )
        );
    };

    const handleSubmission = async () => {
        const apiUrl = `${externalConfig?.scannerUrl || process.env.REACT_APP_REPORT_API_URL}/missing-artifact/DYNAMIC_CALL`;

        if (tableValue.every((artifact) => artifact.value === undefined)) {
            dispatch(setActiveModal(EAppModal.MISSING_ARTIFACT_ALERT));
            return;
        }

        try {
            const formData = new FormData();
            tableValue.forEach((artifact) => {
                formData.append('name', artifact.variableName);
                formData.append('value', artifact.value);
            });

            const response = await axios.post(apiUrl, formData);
            setSuccessMessage("Data successfully saved!");

            setTimeout(() => setSuccessMessage(""), 3000);

        } catch (error) {
            console.error('Error saving data:', error);
            setError('An error occurred while saving data.');
        } finally {
            const currentIndex = missingPages.findIndex((page) => page.status === true);
            if (currentIndex !== -1 && currentIndex < missingPages.length - 1) {
                const nextIndex = currentIndex + 1;
                const nextPage = missingPages[nextIndex];
                dispatch(setCurrentIntent(nextPage.key));
                dispatch(setNextMissingPage({ page: nextPage.key }));
                const updatedMissingPages = missingPages.map((page, index) =>
                    index === nextIndex ? { ...page, status: true }
                        : index === currentIndex ? { ...page, status: false }
                            : page
                );
                dispatch(setNextMissingPageTest(updatedMissingPages));
            } else {
                dispatch(setCurrentIntent(EAppIntent.ARTIFACT_UPLOAD));
                dispatch(setNextMissingPage({ page: undefined }));
            }
        }
    };

    const allowedExtensions = ["csv"];

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setError("");
        if (e.target.files?.length) {
            const inputFile = e.target.files[0];

            const fileExtension = inputFile?.name.split('.').pop()?.toLowerCase();
            if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
                setError("Please input a CSV file");
                return;
            }
            setFile(inputFile);
            parseCSV(inputFile);
        }
    };

    const parseCSV = (file: File) => {
        Papa.parse(file, {
            header: true,
            complete: (results) => {
                const parsedData = results.data as Artifact[];
                const cleanedData = parsedData.map(artifact => ({
                    ...artifact,
                    programName: '-'
                }));
                setTableValue(cleanedData);
            },
            error: (_error) => {
                setError("Error parsing CSV file");
            }
        });
    };

    const csvData = [
        ["variableName", "value"],
        ...tableValue.map(artifact => [artifact.variableName, artifact.value])
    ];

    const headers: React.ReactNode[] = [
        'Variable Name',
        'Program Name',
        'Resolve Program',
    ];

    const renderRowFields = (artifact: Artifact) => {
        const variableName = artifact.variableName;
        return [
            artifact.variableName,
            artifact.programName,
            <Dropdown
                key={variableName}
                options={missingPrograms}
                selectedOption={artifact.value || 'Select'}
                onOptionSelect={(option: string) => handleOptionSelects(variableName, option)}
            />
        ];
    };
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [toggleValue, setToggleValue] = useState<boolean | undefined>(false);
    const options = ['Active', 'Ignore', 'Assembler'];
    const [data, setData] = useState<TableData[]>([]);
    const [headerOption, setHeaderOption] = useState('Active');
    const uploadMissingListRef = useRef<UploadMissingListRef>(null);
    const uploadHeaders: React.ReactNode[] = [
        <Dropdown
            options={options}
            selectedOption={headerOption}
            onOptionSelect={option => handleHeaderDropdownChange(option)}
            key="1"
        />,
        'Variable Name',
        'Program Name',
        !data.every(item => item.uploaded) && 'Upload',
    ];
    const handleHeaderDropdownChange = newOption => {
        // const newOption = e.target.value;
        setHeaderOption(newOption);
        setData(
            data.map(row => ({
                ...row,
                selectedOption: newOption,
            })),
        );
    };
    const handleOptionSelect = (option, index) => {
        const newData = [...data];
        newData[index].selectedOption = option;
        setData(newData);
    };
    const triggerFileUpload = (event, itemKey) => {
        if (uploadMissingListRef.current) {
            uploadMissingListRef.current.handleFileUpload(event, itemKey);
        }
    };
    const rowFields = item => [
        <Dropdown
            options={options}
            selectedOption={item.selectedOption}
            onOptionSelect={option => handleOptionSelect(option, item.key)}
            key={item.key}
        />,
        <div
            className={`text-sm ${item.selectedOption !== 'Active' ? 'text-gray-400' : 'text-gray-900'}`}
            key={item.key}
        >
            {item.requiredBy}
        </div>,
        <div
            className={`text-sm ${item.selectedOption !== 'Active' ? 'text-gray-400' : 'text-gray-900'}`}
            key={item.key}
        >
            {item.programName}
        </div>,
        !toggleValue &&
        (item.uploaded ? ""
            : (
                <MissingUploadBtn
                    handleFileUpload={triggerFileUpload}
                    item={item}
                    buttonStyle={{
                        backgroundColor:
                            item.selectedOption !== 'Active' ? '#e0e0e0' : '#007bff',
                        cursor:
                            item.selectedOption !== 'Active' ? 'not-allowed' : 'pointer',
                    }}
                    accept={`${VALID_PROGRAM_FILE_TYPES.map(type => '.' + type).join(',')}`}
                />
            )),
    ];

    const [datasetNames, setDatasetNames] = useState<string>('');
    const [errors, setErrors] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        const validationError = isValidDataset(value.trim());

        if (validationError) {
            setErrors(validationError);
            setSuccess(null);
        } else {
            setErrors(null);
            setSuccess(null);
        }
        setDatasetNames(value);
    };

   
    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const validationError = isValidDataset(datasetNames.trim());
        if (validationError) {
            setErrors(validationError);
            setSuccess(null);
            return;
        }

        if (!datasetNames.trim()) {
            setErrors('Dataset name is required.');
            setSuccess(null);
            return;
        }

        const data: ExtractJclRequest = {
            dataset: {
                listing: [datasetNames.trim()]
            },
            downloadFor: 'LISTING' as ExtractType
        };


        const isDownloadSuccessful = await downloadExtractJcl(data);

        if (isDownloadSuccessful) {
            setSuccess('Data successfully posted and file downloaded!');
            setErrors(null);
            setDatasetNames('');
        } else {
            setErrors('Failed to post data or download the file. Please try again.');
            setSuccess(null);
        }
    };

    return (
        <div className={`container p-6 ${isMobile ? 'sm:px-6' : 'lg:px-8'} max-w-[95%]`}>
            <div className='p-2 px-8 mb-4'>
                <StepperComponent />
            </div>
            <div className='mb-2 border rounded-lg px-8 py-8 shadow max-w-full'>
                <h1 className="text-3xl font-semibold mb-4">
                    Missing Artifacts Workspace
                </h1>
                <hr />
                <div className="flex flex-col gap-4">
                    <div className="text-left mt-4 gap-4 text-l font-semibold">
                        Resolve Dynamic Calls
                    </div>
                    <div>
                        <p>Resolve the below Dynamic Variables by using the existing programs</p>
                    </div>
                    <div className="flex items-center gap-4">
                        <HelpTextCallout calloutText={pageHelpText.programTableData[4].description}/>
                        <span className="text-sm">Upload Programs</span>
                        <Toggle className="mt-2" onChange={changeDisplay} />
                        <span className="text-sm">Extract Programs from Mainframe</span>
                    </div>
                  

                    {toggleValue && (
                        <div className="flex flex-col gap-4 mt-4">
                            <h3>Enter the comma-separated Dataset names</h3>
                            <input
                                type="text"
                                className="border border-gray-300 rounded-md w-1/4 p-2 mt-2"
                                value={datasetNames}
                                onChange={handleChange}
                                placeholder="Enter dataset names..."
                            />
                            {errors && (
                                <TooltipHost content={errors} id="error-tooltip">
                                    <div className="mt-2 text-red-500">{errors}</div>
                                </TooltipHost>
                            )}
                            {success && (
                                <TooltipHost content={success} id="success-tooltip">
                                    <div className="mt-2 text-green-500">{success}</div>
                                </TooltipHost>
                            )}
                                <div>
                                    {toggleValue && (
                                        Array.isArray(data) &&
                                            data.length > 0 &&
                                            data.every(
                                                item => item.selectedOption !== 'Active' || item.uploaded !== null
                                            ) || Object.values(files).length > 0 || toggleValue || true ? (
                                            <div className='flex flex-row items-center mt-8'>
                                                <HelpTextCallout calloutText={pageHelpText.programTableData[6].description}/>
                                                <PrimaryButton
                                                  text={'Generate Extraction JCL'}
                                                  className="rounded-md"
                                                  styles={{ root: { backgroundColor: '#1363DF' } }}
                                                  onClick={handleSubmit}
                                                />
                                              </div>
                                        ) : null
                                    )}
                                </div>
                            </div>
                     
                    )}
                 
                    {/* <div className="flex justify-end items-center gap-4 p-6">
                        <CSVLink
                            data={csvData}
                            filename={"resolve-dynamic.csv"}
                            className="mt-8 rounded-md text-white font-semibold py-2 px-4 shadow-lg transition duration-300"
                            style={{ backgroundColor: '#1363DF' }}
                        >
                            Download CSV Template
                        </CSVLink>

                        <label
                            htmlFor="csv-upload"
                            className="mt-8 rounded-md text-white font-semibold py-2 px-4 shadow-lg transition duration-300"
                            style={{ backgroundColor: '#1363DF' }}
                        >
                            Upload CSV
                        </label>
                        <input
                            id="csv-upload"
                            type="file"
                            accept=".csv"
                            onChange={handleFileChange}
                            className="hidden"
                        />
                    </div> */}
                    {!toggleValue ? (
                        <>
                            {successMessage && <p className="text-green-600">{successMessage}</p>}
                            {loading ? (
                                <div className="flex flex-col items-center">
                                    <FaSpinner className="animate-spin text-4xl text-gray-600" />
                                    <p className="mt-4 text-gray-600">Loading...</p>
                                </div>
                            ) : tableValue.length > 0 ? (
                                <ReusableTable
                                    headers={headers}
                                    items={tableValue}
                                    rowFields={renderRowFields}
                                    helpTexts = {pageHelpText.dynamicResolveTable}
                                />
                            ) : (
                                <>
                                    <ReusableTable
                                        headers={headers}
                                        items={tableValue}
                                        rowFields={renderRowFields}
                                        helpTexts = {pageHelpText.dynamicResolveTable}
                                    />
                                    <p className="text-gray-600">No data available</p>
                                </>
                            )}
                            <div>
                                <h2 className="flex items-center justify-center font-bold mb-4">OR</h2>
                                <h2 className="flex text-left mb-4">Upload the Programs to resolve the Dynamic Call</h2>
                                <ReusableTable items={data} headers={uploadHeaders} rowFields={rowFields} helpTexts={pageHelpText.programTableData}/>
                                {!toggleValue ? (
                                    <MissingUploadList ref={uploadMissingListRef} setData={setData} type="DYNAMIC CALL" data={data} pageHelpText={pageHelpText.programTableData} />
                                ) : null}
                            </div>
                            {!toggleValue ? (
                                <div className="flex">
                                    <PrimaryButton
                                        onClick={handleSubmission}
                                        text={'Save and Continue'}
                                        className="mt-8 rounded-md"
                                        styles={{ root: { backgroundColor: '#1363DF' } }}
                                    />
                                </div>
                            ) : null}
                        </>
                    ) : null}

                   
                  
                </div>
            </div>
        </div>
    );
};

export default ResolveDynamic;
