import {
    initializeIcons,
    PrimaryButton,
    Toggle,
    IconButton,
    TooltipHost,
} from '@fluentui/react';
import Papa from 'papaparse';
import { useEffect, useRef, useState } from 'react';
import SVGTrash from '../../../assets/images/svg/trash.icon';
import ProgramTable from '../../../components/data-table/data-program-table';
import { VALID_PROGRAM_FILE_TYPES } from './missing-program-types'
import MissingUploadBtn from '../../../components/missing-upload-button/missing-upload-button';
import { UploadFile } from '../../../components/upload-list/upload-list.types';
import axios from 'axios';
import Dropdown from '../../../components/custom-dropdown/custom-dropdown';
import ReusableTable from '../../../components/missing-artifacts-table/missing-artifacts-table';
import { useDispatch, useSelector } from 'react-redux';
import {
    setActiveModal,
    setCurrentIntent,
    setNextMissingPage,
    setNextMissingPageTest,
} from '../../../redux/app-global';
import { EAppIntent, EAppModal, TableData } from '../../../redux/app-global/app-global.types';
import React from 'react';
import { SharedService } from '../../../services/shared-service';
import { downloadExtractJcl, externalConfig, isValidDataset } from '../../../utils/misc.utils';
import { RootState } from '../../../redux/store';
import StepperComponent from '../../../components/stepper-component/stepper-component';
import UploadList from '../../../components/upload-list/upload-list';
import MissingUploadList, { UploadMissingListRef } from '../../../components/missing-artifact-upload/missing-artifact-upload';
import { ExtractJclRequest, ExtractType } from '../extract-artifact/extract-artifact.model';
import { FaSpinner } from 'react-icons/fa';
import { helpTexts } from '../../../helpTexts';
import HelpTextCallout from '../../../components/help-text-callout/help-text-callout';
initializeIcons();

const MissingPrograms = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
    const [toggleValue, setToggleValue] = useState<boolean | undefined>(false);
    const [files, setFiles] = useState<Record<string, UploadFile>>({});
    const options = ['Active', 'Ignore', 'Assembler'];
    const [data, setData] = useState<TableData[]>([]);
    const [progress, setProgress] = useState<number>(0);
    const [rows, setRows] = useState([{ internal: '', external: '' }]);
    const [headerOption, setHeaderOption] = useState('Active');
    const programData = useSelector((state:RootState)=>state.appGlobal.programData);
    const isMounted = useRef(false);
    const dispatch = useDispatch();
    const missingPages = useSelector((state: RootState) => state.appGlobal.missingPages);
    const uploadMissingListRef = useRef<UploadMissingListRef>(null);

    const pagHelpTexts = helpTexts[0].MISSING_PROGRAM;

    const triggerFileUpload = (event, itemKey) => {
        if (uploadMissingListRef.current) {
            uploadMissingListRef.current.handleFileUpload(event, itemKey);
        }
    };
    const headers: React.ReactNode[] = [
        <Dropdown
            options={options}
            selectedOption={headerOption}
            onOptionSelect={option => handleHeaderDropdownChange(option)}
            key="1"
        />,
        'Program',
        'Called By',
        !toggleValue && !data.every(item => item.uploaded) && 'Upload',
    ];
    useEffect(() => {
        function updateFiles() {
            if (uploadMissingListRef.current) {
                const test = uploadMissingListRef.current.getFiles()
                setFiles(test)
            }
        }
        updateFiles();
    }, [uploadMissingListRef.current?.getFiles])
    const rowFields = item => [
        <Dropdown
            options={options}
            selectedOption={item.selectedOption}
            onOptionSelect={option => handleOptionSelect(option, item.key)}
            key={item.key}
        />,
        <div
            className={`text-sm ${item.selectedOption !== 'Active' ? 'text-gray-400' : 'text-gray-900'}`}
            key={item.key}
        >
            {item.programName}
        </div>,
        item.requiredBy.length <= 4 ? (
            <div
                className={`text-sm ${item.selectedOption !== 'Active' ? 'text-gray-400' : 'text-gray-900'}`}
                key={item.key}
            >
                {item.requiredBy.join(', ')}
            </div>
        ) : (
            <div key={item.key}>
                <TooltipHost
                    id={`tooltip-${item.requiredBy[0]}`}
                    content={item.requiredBy
                        .slice(4, item.requiredBy.length - 1)
                        .join(', ')}
                >
                    <div
                        className={`text-sm ${item.selectedOption !== 'Active' ? 'text-gray-400' : 'text-gray-900'}`}
                    >
                        {item.requiredBy.slice(0, 3).join(', ')}
                    </div>
                    <span
                        className={`text-sm ${item.selectedOption !== 'Active' ? 'text-gray-400' : 'text-gray-900'}`}
                    >
                        ... and {item.requiredBy.length - 3} more
                    </span>
                </TooltipHost>
            </div>
        ),
        !toggleValue &&
        (item.uploaded ? ""
            : (
                <MissingUploadBtn
                    handleFileUpload={triggerFileUpload}
                    item={item}
                    buttonStyle={{
                        backgroundColor:
                            item.selectedOption !== 'Active' ? '#e0e0e0' : '#007bff',
                        cursor:
                            item.selectedOption !== 'Active' ? 'not-allowed' : 'pointer',
                    }}
                    accept={`${VALID_PROGRAM_FILE_TYPES.map(type => '.' + type).join(',')}`}
                />
            )),
    ];
    useEffect(() => {
        if(programData && programData?.length > 0){
            setData(programData)
            return;
          }
        async function getPrograms() {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_REPORT_API_URL}/missing-artifact/PROGRAM`,
                );
                const transformedData: TableData[] = response.data?.data.map(
                    (item: any, index: number) => ({
                        key: index,
                        selectedOption: 'Active',
                        programName: item.name,
                        requiredBy: item.referredIn.callerName,
                        uploaded: null,
                    }),
                );
                setData(transformedData);
            } catch (err) {
                console.error(err);
            }
        }
        if (!isMounted.current) {
            getPrograms();
            isMounted.current = true;
        }
    }, []);
    const handleHeaderDropdownChange = newOption => {
        // const newOption = e.target.value;
        setHeaderOption(newOption);
        setData(
            data.map(row => ({
                ...row,
                selectedOption: newOption,
            })),
        );
    };
    const handleInputChange = (index, column, value) => {
        if (value.trim()) {
            setInternalError(null);
        }
        const newRows = [...rows];
        newRows[index][column] = value;
        setRows(newRows);
    };

    const handleAddRow = () => {
        setRows([...rows, { internal: '', external: '' }]);
    };

    const handleRemoveRow = index => {
        const newRows = rows.filter((_, i) => i !== index);
        setRows(newRows);
    };

    const handleOptionSelect = (option, index) => {
        const newData = [...data];
        newData[index].selectedOption = option;
        setData(newData);
    };
    const sendProgramInfo = async () => {
        const formData = new FormData();

        data.forEach(row => {
            formData.append('programName', row.name);
            formData.append('status', row.selectedOption || '');
            formData.append('referredIn', JSON.stringify(row.requiredBy));
            if (row.uploaded) {
                formData.append('file', row.uploaded as Blob);
            }
        });

        if ((data.every(
            item => item.status !== 'Active' && item.uploaded !== null,
        ) ||
            !(Object.values(files).length > 0))) {
            dispatch(setActiveModal(EAppModal.MISSING_ARTIFACT_ALERT));
            return;

        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_REPORT_API_URL}/missing-artifact/PROGRAM`,
                formData,
            );
        } catch (err) {
            console.log(err);
        } finally {
            const currentIndex = missingPages.findIndex((page) => page.status === true);
            if (currentIndex !== -1 && currentIndex < missingPages.length - 1) {
                const nextIndex = currentIndex + 1;
                const nextPage = missingPages[nextIndex];
                dispatch(setCurrentIntent(nextPage.key));
                dispatch(setNextMissingPage({
                    page: nextPage.key,
                }));
                const updatedMissingPages = missingPages.map((page, index) =>
                    index === nextIndex ? { ...page, status: true }
                        : index === currentIndex ? { ...page, status: false }
                            : page
                );
                dispatch(setNextMissingPageTest(updatedMissingPages));
            } else {
                dispatch(setCurrentIntent(EAppIntent.ARTIFACT_UPLOAD));
                dispatch(
                    setNextMissingPage({
                        page: undefined,
                    }),
                );
            }
        }
    };
    const changeDisplay = (
        ev: React.MouseEvent<HTMLElement>,
        checked?: boolean,
    ) => {
        setToggleValue(checked);
    };
    const downloadCSVTemplate = () => {
        const header = ['External Program Patterns', 'Internal Program Patterns'];
        const csvRows = [
            header.join(','),
            ...rows.map(row => [row.internal, row.external].join(',')),
        ];

        const csvContent = csvRows.join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'MissingProgramsTemplate.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const handleCSVFileUpload = event => {
        const file = event.target.files[0];
        if (file) {
            Papa.parse(file, {
                header: true,
                complete: results => {
                    const parsedRows = results.data
                        .filter(
                            row =>
                                row['Internal Program Patterns'] ||
                                row['External Program Patterns'],
                        )
                        .map(row => ({
                            internal: row['Internal Program Patterns'] || '',
                            external: row['External Program Patterns'] || '',
                        }));
                    setRows(parsedRows);
                },
                error: error => {
                    console.error('Error parsing CSV:', error);
                },
            });
        }
    };
    const [datasetNames, setDatasetNames] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        const validationError = isValidDataset(value.trim());

        if (validationError) {
            setError(validationError);
            setSuccess(null);
        } else {
            setError(null);
            setSuccess(null);
        }
        setDatasetNames(value);
    };

    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const validationError = isValidDataset(datasetNames.trim());
        if (validationError) {
            setError(validationError);
            setSuccess(null);
            return;
        }

        if (!datasetNames.trim()) {
            setError('Dataset name is required.');
            setSuccess(null);
            return;
        }

        const data: ExtractJclRequest = {
            dataset: {
                listing: [datasetNames.trim()]
            },
            downloadFor: 'LISTING' as ExtractType
        };


        const isDownloadSuccessful = await downloadExtractJcl(data);

        if (isDownloadSuccessful) {
            setSuccess('Data successfully posted and file downloaded!');
            setError(null);
            setDatasetNames('');
        } else {
            setError('Failed to post data or download the file. Please try again.');
            setSuccess(null);
        }
    };

    const [internalError, setInternalError] = useState<string | null>(null);
    const [internalSuccess, setInternalSuccess] = useState<string | null>(null);
    const InternalFormSubmit = async () => {
        const data = rows.reduce((acc, row, index) => {
            const internalKey = `additionalProp${index * 2 + 1}`;
            const externalKey = `additionalProp${index * 2 + 2}`;

            if (row.internal.trim() !== '') {
                acc[internalKey] = row.internal;
            }

            if (row.external.trim() !== '') {
                acc[externalKey] = row.external;
            }

            return acc;
        }, {} as { [key: string]: string });

        if (Object.keys(data).length === 0) {
            setInternalError('Please enter at least one valid pattern.');
            setInternalSuccess(null);
            return;
        }
        try {
            const apiUrl = `${externalConfig?.scannerUrl || process.env.REACT_APP_REPORT_API_URL}/missing-artifact/program-mapping`;
            const response = await axios.post(apiUrl, data);
            setInternalSuccess('Data successfully posted!');
            setInternalError(null);
        } catch (err) {
            setInternalError('Failed to post data. Please try again.');
            setInternalSuccess(null);
        }
    };

  

    return (
        <div
            className={`container p-6 ${isMobile ? 'sm:px-6' : 'lg:px-8'} max-w-full`}
        >

            <div className='p-2 px-8 mb-4'>
                <StepperComponent />
            </div>
            <div className='mb-2 border rounded-lg px-8 py-8 shadow max-w-full'>
                <h1 className="text-3xl font-semibold mb-4">
                    Missing Artifacts Workspace
                </h1>

                <hr />
                <div className="flex flex-col gap-4">
                    <div className="text-left mt-4 gap-4 text-l font-semibold">
                        Missing Program Information
                    </div>
                    <div>
                        Provide dataset for the missing Programs below or upload the missing
                        Programs.
                    </div>
                    <div className="flex items-center gap-4">
                        <HelpTextCallout calloutText={pagHelpTexts[4].description}/>
                        <span className="text-sm">Upload Programs</span>
                        <Toggle className="mt-2" onChange={changeDisplay} />
                        <span className="text-sm">Extract Programs from Mainframe</span>
                    </div>
                    {toggleValue && (
                        <div>
                            <h3>Enter the comma-separated Dataset names</h3>
                            <input
                                type="text"
                                className="border border-gray-300 rounded-md w-1/4 p-2 mt-2"
                                value={datasetNames}
                                onChange={handleChange}
                                placeholder="Enter dataset names..."
                            />
                            {error && (
                                <TooltipHost content={error} id="error-tooltip">
                                    <div className="mt-2 text-red-500">{error}</div>
                                </TooltipHost>
                            )}
                            {success && (
                                <TooltipHost content={success} id="success-tooltip">
                                    <div className="mt-2 text-green-500">{success}</div>
                                </TooltipHost>
                            )}
                        </div>
                    )}
                    <div>
                        {toggleValue && (
                            Array.isArray(data) &&
                                data.length > 0 &&
                                data.every(
                                    item => item.selectedOption !== 'Active' || item.uploaded !== null
                                ) || Object.values(files).length > 0 || toggleValue || true ? (
                                    <div className='flex flex-row items-center mt-8'>
                                    <HelpTextCallout calloutText={pagHelpTexts[6].description}/>
                                    <PrimaryButton
                                      text={'Generate Extraction JCL'}
                                      className="rounded-md"
                                      styles={{ root: { backgroundColor: '#1363DF' } }}
                                      onClick={handleSubmit}
                                    />
                                  </div>
                            ) : null
                        )}
                    </div>
                    {!toggleValue && (
                        <>
                            <div className="font-semibold mt-4">Upload Program as File</div>
                            <hr />
                        </>
                    )}
                 
                        <>
                    <div className='flex flex-row items-center'>
                        <h2>Provide name Mapping for Programs if any:</h2>
                        <HelpTextCallout calloutText={pagHelpTexts[7].description}/>
                    </div>
                    <div className="flex flex-row items-center justify-end gap-2">
                        <HelpTextCallout calloutText={pagHelpTexts[8].description}/>
                        <p
                            className="cursor-pointer hover:text-blue-500 text-blue-700 text-sm"
                            onClick={downloadCSVTemplate}
                        >
                            Download CSV Template
                        </p>
                        <HelpTextCallout calloutText={pagHelpTexts[9].description}/>
                        <label
                            htmlFor="csvUpload"
                            className="cursor-pointer hover:text-blue-500 rounded transition duration-300 inline-block text-blue-700 text-sm"
                        >
                            Upload CSV
                        </label>
                        <input
                            id="csvUpload"
                            type="file"
                            accept=".csv"
                            onChange={handleCSVFileUpload}
                            className="hidden"
                        />
                    </div>
                    <div className="mb-2 border rounded-lg p-4 px-2 shadow">
                        <div className="container mx-auto">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead>
                                    <tr>
                                        <th className="px-4 py-2 text-left font-semibold divide-y divide-gray-200">
                                            <div className='flex flex-row items-center gap-2'>
                                                External Program Patterns
                                                <HelpTextCallout calloutText={pagHelpTexts[10].description}/>
                                            </div>
                                            
                                        </th>
                                        <th className="px-4 py-2 text-left font-semibold divide-y divide-gray-200">
                                            <div className='flex flex-row items-center gap-2'>
                                                Internal Program Patterns
                                                <HelpTextCallout calloutText={pagHelpTexts[10].description}/>
                                            </div>
                                        </th>
                                        <th className="px-4 py-2 text-left font-semibold divide-y divide-gray-200">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {rows.map((row, index) => (
                                        <tr key={index}>
                                            <td className="px-4 py-2">
                                                <input
                                                    type="text"
                                                    value={row.internal}
                                                    placeholder="Enter Pattern"
                                                    onChange={e =>
                                                        handleInputChange(index, 'internal', e.target.value)
                                                    }
                                                    className={`w-full rounded px-2 py-1 ${row.internal === '' ? 'border border-gray-300' : ''}`}
                                                />
                                            </td>
                                            <td className="px-4 py-2">
                                                <input
                                                    type="text"
                                                    value={row.external}
                                                    placeholder="Enter Pattern"
                                                    onChange={e =>
                                                        handleInputChange(index, 'external', e.target.value)
                                                    }
                                                    className={`w-full rounded px-2 py-1 ${row.internal === '' ? 'border border-gray-300' : ''}`}
                                                />

                                            </td>

                                            <td className="px-4 py-2 text-l">
                                                <button
                                                    onClick={() => handleRemoveRow(index)}
                                                    className="bg-red-500 text-white px-2 py-1 rounded text-sm hover:bg-red-700"
                                                >
                                                    Remove
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="mt-4 flex flex-row items-center justify-end mr-16">
                                <button
                                    onClick={handleAddRow}
                                    className="px-4 py-2 right-0 hover:text-blue-500 text-blue-700 text-sm"
                                >
                                    Add Row
                                </button>
                            </div>
                            <div>
                                {internalError && (
                                    <TooltipHost content={internalError} id="error-tooltip">
                                        <div className="mt-2 text-red-500">{internalError}</div>
                                    </TooltipHost>
                                )}
                                {internalSuccess && (
                                    <TooltipHost content={internalSuccess} id="success-tooltip">
                                        <div className="mt-2 text-green-500">{internalSuccess}</div>
                                    </TooltipHost>
                                )}
                            </div>
                            <div className="mt-4 mx-4">
                                <button className="px-4 py-2 right-0 bg-blue-500 text-white rounded-md text-sm" onClick={InternalFormSubmit}>

                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                    </>
                    <ReusableTable items={data} headers={headers} rowFields={rowFields} helpTexts={pagHelpTexts}/>
                    {!toggleValue ? (

                         <MissingUploadList ref={uploadMissingListRef} setData={setData} type="PROGRAMS" data={data} pageHelpText={pagHelpTexts}/>                           

                    ) : (''
                    )}
                </div>

                {!toggleValue && (
                    <div>
                        {Array.isArray(data) && (
                            (data.every(
                                item => item.selectedOption !== 'Active' || item.uploaded !== null
                            ) || Object.values(files).length > 0 || toggleValue || true) && (
                                <PrimaryButton
                                    text="Save and Continue"
                                    className="mt-8 rounded-md"
                                    styles={{ root: { backgroundColor: '#1363DF' } }}
                                    onClick={sendProgramInfo}
                                />
                            )
                        )}
                    </div>
                )}

            </div>
        </div>
    );
};
export default MissingPrograms;
