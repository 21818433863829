import { useEffect, useState } from "react";
import { FaBitbucket, FaCheckCircle, FaGithub, FaGitlab } from "react-icons/fa";
import { TbLogs } from "react-icons/tb";

const DeployProgress = ({ selectedPlatform, job, setData }) => {
    const [progress, setProgress] = useState(job.progress);
    const [completionDate, setCompletionDate] = useState<Date>();

    useEffect(() => {
        let intervalId;
        const intervalTime = 100;

        if (job.expanded && progress < 100 && !job.success) {
            intervalId = setInterval(() => {
                setData(prevData =>
                    prevData.map(j => {
                        if (j.jobName === job.jobName) {
                            const newProgress = Math.min(j.progress + 1, 100);
                            if (newProgress >= 100) {
                                clearInterval(intervalId);                                
                                setProgress(newProgress);
                                return { ...j, progress: newProgress, success: true, completionDate: new Date().toLocaleString() };
                            }                            
                            setProgress(newProgress);
                            return { ...j, progress: newProgress, success: false };
                        }
                        return j;
                    })
                );
            }, intervalTime);
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [job, progress, setData]);

    const platformTitles = {
        github: 'GitHub',
        gitlab: 'GitLab',
        bitbucket: 'Bitbucket'
    };

    const platformIcons = {
        github: <FaGithub />,
        gitlab: <FaGitlab />,
        bitbucket: <FaBitbucket />
    };

    return (
        <div>
            <div className="progress-header flex justify-between items-center mb-4">
                <div className="text-center font-semibold text-lg flex items-center justify-center">
                    <span className="mr-2">Deploying to {platformTitles[selectedPlatform]}</span>
                    {platformIcons[selectedPlatform]}
                </div>
                <span className="text-gray-500 text-sm">{progress || 0}% Complete</span>
            </div>

            <div className="progress-bar flex flex-col space-y-4">
                <div className="step flex items-center">
                    {progress >= 35 ? (
                        <div className="rounded-full h-6 w-6 text-sm flex items-center justify-center">
                            <span><FaCheckCircle color="#4CAF50" size={24} /></span>
                        </div>
                    ) : (
                        <div className="step-icon bg-blue-500 text-white rounded-full h-6 w-6 text-sm flex items-center justify-center">
                            1
                        </div>
                    )}
                    <div className="step-content flex-1 ml-4">
                        <div className="flex justify-between">
                            <div className="step-label font-medium text-gray-700">
                                Establishing connection
                            </div>
                            <div className="step-percentage text-gray-500 text-sm">
                                {progress ? `${Math.min(progress * 3, 100)}%` : '0%'}
                            </div>
                        </div>
                        <div className="bg-gray-200 rounded h-2 mt-1">
                            <div className="progress-fill h-2 rounded" style={{
                                width: progress ? `${Math.min(progress * 3, 100)}%` : '0%',
                                background: 'linear-gradient(90deg, #4CAF50, rgb(58 200 64))',
                                borderRadius: '8px',
                                transition: 'width 0.5s ease-in-out',
                            }}>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="step flex items-center">
                    {progress - 35 >= 35 ? (
                        <div className="rounded-full h-6 w-6 text-sm flex items-center justify-center">
                            <span><FaCheckCircle color="#4CAF50" size={24} /></span>
                        </div>
                    ) : (
                        <div className="step-icon bg-blue-500 text-white rounded-full h-6 w-6 text-sm flex items-center justify-center">
                            2
                        </div>
                    )}
                    <div className="step-content flex-1 ml-4">
                        <div className="flex justify-between">
                            <div className="step-label font-medium text-gray-700">
                                Creating Initial commit
                            </div>
                            <div className="step-percentage text-gray-500 text-sm">
                                {progress ? `${Math.min(Math.max(progress - 35, 0) * 3, 100)}%` : '0%'}
                            </div>
                        </div>
                        <div className="bg-gray-200 rounded h-2 mt-1">
                            <div
                                className="progress-fill bg-blue-500 h-2 rounded"
                                style={{
                                    width: progress ? `${Math.min(Math.max(progress - 35, 0) * 3, 100)}%` : '0%',
                                    background: 'linear-gradient(90deg, #4CAF50, rgb(58 200 64))',
                                    borderRadius: '8px',
                                    transition: 'width 0.5s ease-in-out',
                                }}
                            ></div>
                        </div>
                    </div>
                </div>

                <div className="step flex items-center">
                    {progress >= 100 ? (
                        <div className="rounded-full h-6 w-6 text-sm flex items-center justify-center">
                            <span><FaCheckCircle color="#4CAF50" size={24} /></span>
                        </div>
                    ) : (
                        <div className="step-icon bg-blue-500 text-white rounded-full h-6 w-6 text-sm flex items-center justify-center">
                            3
                        </div>
                    )}
                    <div className="step-content flex-1 ml-4">
                        <div className="flex justify-between">
                            <div className="step-label font-medium text-gray-700">
                                Pushing the code
                            </div>
                            <div className="step-percentage text-gray-500 text-sm">
                                {progress >= 100
                                    ? '100%'
                                    : `${Math.max(progress - 70, 0)}%`}
                            </div>
                        </div>
                        <div className="bg-gray-200 rounded h-2 mt-1">
                            <div
                                className="progress-fill bg-blue-500 h-2 rounded"
                                style={{
                                    width: progress >= 100
                                        ? '100%'
                                        : `${Math.max(progress - 70, 0)}%`,
                                    background: 'linear-gradient(90deg, #4CAF50, rgb(58 200 64))',
                                    borderRadius: '8px',
                                    transition: 'width 0.5s ease-in-out',
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </div>

            {progress === 100 && job.success && (
                <div className="progress-footer mt-8 flex justify-between items-center">
                    <div className="text-green-600 text-sm font-semibold">
                        Deployed successfully on {job.completionDate}.
                    </div>
                    <div className="flex justify-end mb-4">
                        <button className="text-white flex items-center gap-2 bg-cta px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cta">
                            <TbLogs /> View Logs
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DeployProgress;
