import { createChatBotMessage } from 'react-chatbot-kit';
import IConfig from 'react-chatbot-kit/build/src/interfaces/IConfig';
import { useDispatch } from 'react-redux';
import myImage from '../../assets/images/svg/CF-Logo-Mark-Blue.svg';
import logoWhite from '../../assets/images/svg/CF-Logo-Mark-White.svg';
import releaseVersions from '../../assets/release_version.json';
import { setActiveModal } from '../../redux/app-global';
import { EAppModal } from '../../redux/app-global/app-global.types';
import { appTheme } from '../../styles/theme';
import Options from './widgets/options';

const botName = 'Code Navigator';

const options = [
  {
    id: '001',
    message: 'Discover Cloudframe',
    type: 'prompt-request',
    intent: 'ABOUT_US',
  },
  {
    id: '002',
    message: 'Start your Modernization Journey',
    type: 'prompt-request',
  },
  {
    id: '003',
    message: 'Contact Cloudframe',
    type: 'prompt-request',
    intent: 'CONTACT_US',
  },
];

export interface IChatResponse {
  message: string;
  options: any[];
}

export const getChatbotConfig = (handleRestart: any): IConfig => ({
  initialMessages: [
    createChatBotMessage(
      `CloudFrame AI Plugin is not activated. It will be automatically activated once you reach readiness stage`,
      {},
    ),
    // createClientMessage(
    //   'We would Like to modernize our mainframe application. how can IFrame help with that process?',
    //   {},
    // ),
    // createChatBotMessage('Please check Information Center for Answer...', {}),
  ],
  botName,
  customStyles: {
    botMessageBox: {
      backgroundColor: appTheme.colors.whiteLight || '#40A2E3',
    },
    chatButton: {
      backgroundColor: '#5ccc9d',
    },
  },
  customComponents: {
    header: () => {
      const dispatch = useDispatch();

      const showAppVersion = () => {
        dispatch(setActiveModal(EAppModal.APP_VERSION));
      };

      return (
        <div className=" shadow-sm">
          <div
            style={{
              background:
                '#9CECFB linear-gradient(to right, #0052d4, #65c7f7, #9cecfb)',
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4,
            }}
            className="flex flex-row gap-2 px-2 py-4 bg-primary items-center"
            // style={{
            //   paddingBottom: '1.5rem',
            //   borderRadius: '100% /  0 0 15px 15px',
            // }}
          >
            <div className="w-[64px] h-[64px] bg-white rounded-full flex justify-center items-center">
              <img src={myImage} alt="My Image" width={30} height={30} />
            </div>
            <div>
              <div className="text-white text-sm font-semibold -mb-[2px]">
                Chat with
              </div>
              <div
                className="text-white font-bold text-l"
                style={{ fontSize: '18px' }}
              >
                {botName}
              </div>
              <p
                className="text-xs px-4 py-[2px] cursor-pointer bg-white rounded-full shadow-sm hover:shadow-md hover:scale-105"
                onClick={showAppVersion}
              >
                Version: {releaseVersions.codenavigator}
              </p>
              {/* <div className="flex flex-row items-center gap-1">
                <div className="w-2 h-2 bg-tealSmooth rounded-full" />
                <div className="text-white text-sm">Online</div>
              </div> */}
            </div>
          </div>
          <div
            style={{ fontSize: '15px' }}
            className="py-4 px-2 pl-4 text-md clip-header flex flex-row items-center gap-1 relative"
          >
            <div className="w-2 h-2 bg-greenBase rounded-full" />
            <div className="ml-1 text-white text-[15px]">
              Online <span>(I reply instantly)</span>
            </div>
            <button
              onClick={() => {
                handleRestart();
              }}
              className="text-white text-sm ml-auto px-4 py-[.4rem] bg-blue-500 rounded-md absolute right-0 mb-1 mr-1"
            >
              Restart Session
            </button>
          </div>
        </div>
      );
    },
    botAvatar: () => {
      return (
        <div>
          <div
            style={{ background: '#47B5FF' }}
            className="w-[44px] h-[44px] bg-darkBrownSmoothLight rounded-full flex justify-center items-center mr-3"
          >
            <img src={logoWhite} alt="My Image" width={18} height={18} />
          </div>
        </div>
      );
    },
  },
  widgets: [
    {
      widgetName: 'options',
      widgetFunc: props => <Options {...props} />,
      mapStateToProps: ['messages'],
      props: {
        hello: ' hello',
      },
    },
  ],
});
