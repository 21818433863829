import axios from 'axios';
import { downloadBase64FromResponse } from '../../../utils/misc.utils';

export const downloadLog = async () => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + '/download/scan-log',
    );
    downloadBase64FromResponse(res);
  } catch (err) {
    console.log(err);
  }
};

export const downloadCode = async (name: string) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + '/download/generated-code/' + name,
    );
    downloadBase64FromResponse(res);
  } catch (err) {
    console.log(err);
  }
};
