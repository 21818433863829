import { color } from 'html2canvas/dist/types/css/types/color';
import React, { Suspense, useEffect, useState } from 'react';
import SVGLoader from '../../../../assets/images/svg/loader.icon';
import { Spin } from 'antd';
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';
import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align';
import { fontStyle } from 'html2canvas/dist/types/css/property-descriptors/font-style';
const DiffViewer = React.lazy(() => import('react-diff-viewer-continued'));

const CompareComponent = ({ oldString, newString }) => {
    return (
        <div>            
            <Suspense fallback={
                <Spin
                size="large"
                tip="Loading the result.."
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center",
                }}
              />
            }>
                    <DiffViewer
                        oldValue={oldString}
                        newValue={newString}
                        splitView={true}
                        leftTitle={"CLFQA.BASELINE.REPOUT.MF (MainFrame Output)"}
                        rightTitle={"CLFQA.BASELINE.REPOUT.CF (Java Output)"}
                        showDiffOnly = {false} 
                        styles={{
                            contentText: { fontSize: "12px", padding: "5px" },
                            lineNumber: { fontSize: "12px" },
                            titleBlock: { 
                                position: "sticky",
                                top: 0,
                                backgroundColor: "#d9d9d9",
                                zIndex: 1,
                                padding: "5px", 
                                fontWeight:"bold",
                                textAlign: "center",
                                fontSize: "24px"     
                            }
                        }}
                    />
            </Suspense>
        </div>
    );
};

export default CompareComponent;
