import React, { useState, useRef, useId } from 'react';
import { IconButton, Callout, DirectionalHint, DefaultButton,IButtonStyles } from '@fluentui/react';

interface HelpTextCalloutProps {
  calloutText: string | undefined;
  buttonAriaLabel?: string;
  buttonStyles?: IButtonStyles;
}

const HelpTextCallout:React.FC<HelpTextCalloutProps> = ({ calloutText, buttonAriaLabel = 'Info',buttonStyles }) => {
    const [isCalloutVisible, setIsCalloutVisible] = useState(false);
    const buttonContainerRef = useRef(null); // Ref to the container wrapping the button
  
    const onShowCallout = () => setIsCalloutVisible(true);
    const onHideCallout = () => setIsCalloutVisible(false);
  
    return (
      <div ref={buttonContainerRef}>
        <IconButton
          iconProps={{ iconName: 'Info' }}
          title={buttonAriaLabel}
          ariaLabel={buttonAriaLabel}
          onClick={onShowCallout}
          styles={buttonStyles}
        />
        {isCalloutVisible && (
          <Callout
            target={buttonContainerRef.current} // Attach the Callout to the wrapping div
            onDismiss={onHideCallout}
            directionalHint={DirectionalHint.bottomCenter} // Adjust position as needed
            setInitialFocus
            calloutMaxWidth={320}
          >
            <div style={{ 
                padding: '16px',wordWrap: 'break-word', 
                overflowWrap: 'break-word'}}>
            {calloutText}
            </div>
            <div style={{ padding: '16px' }}>
                <DefaultButton onClick={onHideCallout} text="Click to dismiss"/>
            </div>
          </Callout>
        )}
      </div>
    );
  };

export default HelpTextCallout;
