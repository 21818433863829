import React from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';

interface FileTypeTableProps {
    title: string;
    rows: Array<{
        datasetName: string;
        recordLength: number;
        recordFormat: string;
        fileType: string;
    }>;
    downloadStatus: boolean;
    tableClassName: string;
    headerClassName: string;
    rowClassName: string;
}

const FileTypeTable: React.FC<FileTypeTableProps> = ({ title, rows, downloadStatus, tableClassName, headerClassName, rowClassName }) => {
    return (
        <>
            {rows.length > 0 && (
                <>
                    <h2 className={`text-lg font-semibold mb-2 ${headerClassName}`}>{title}</h2>
                    <table className={`w-full shadow-md rounded-md ${tableClassName}`}>
                        <thead>
                            <tr className='border-b'>
                                <th className="p-4 text-left font-medium text-muted-foreground w-[250px] text-sm">Dataset Name</th>
                                <th className="p-4 text-center font-medium text-muted-foreground w-[100px] text-sm">Record Length</th>
                                <th className="p-4 text-left font-medium text-muted-foreground w-[150px] text-sm">Record Format</th>
                                <th className="p-4 text-left font-medium text-muted-foreground w-[200px] text-sm">File Type</th>
                                <th className="p-4 text-center font-medium text-muted-foreground w-[200px] text-sm">Download Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row, rowIndex) => (
                                <tr key={rowIndex} className={`border-b ${rowClassName}`}>
                                    <td className={`p-4 align-middle font-semibold text-sm`}>
                                        {row.datasetName}
                                    </td>
                                    <td className={`p-4 align-middle text-center font-semibold text-sm`}>
                                        {row.recordLength}
                                    </td>
                                    <td className={`p-4 align-middle font-semibold text-sm`}>
                                        {row.recordFormat}
                                    </td>
                                    <td className={`p-4 align-middle font-semibold text-sm`}>
                                        {row.fileType}
                                    </td>
                                    <td className="p-4 align-middle text-center">
                                        {downloadStatus ? (
                                            <FaCheck className="text-green-500 inline-block" />
                                        ) : (
                                            <FaTimes className="text-red-500 inline-block" />
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
        </>
    );
};

export default FileTypeTable;
