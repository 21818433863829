import Table from './table/table';
import Summary from './summary/summary';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveModal, setCurrentIntent } from '../../../redux/app-global';
import {
  EAppIntent,
  EAppModal,
} from '../../../redux/app-global/app-global.types';
import useTransformation from './useTransformation';
import { CiSettings } from 'react-icons/ci';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { MdOutlineFileDownload } from 'react-icons/md';
import { downloadLog } from './transformation.service';
import { RootState } from '../../../redux/store';
import { useCallChainUtils } from '../../../hooks/useCallChainUtils';
import { useMemo } from 'react';
import { helpTexts } from '../../../helpTexts';
import HelpTextCallout from '../../../components/help-text-callout/help-text-callout';


const pageHelpText = helpTexts[0].TRANSFORMATION_PAGE;


const TransformationPage = () => {
  const dispatch = useDispatch();
  // const jcls = testJclRows;
  // const { driverPrograms, otherPrograms, availableProfiles } =
  //   useTransformation();

  const { availableProfiles } = useTransformation();
  const missingPages = useSelector(
    (state: RootState) => state.appGlobal.missingPages,
  );
  const { jclArtifactIds, programIds, programs, jclArtifacts } =
    useCallChainUtils();

  const data = useMemo(() => {
    const artiToExclude = new Set<string>();
    Object.values(missingPages[0]?.data || {}).forEach((data: any) => {
      data.forEach((item: any) => {
        if (item.name) {
          item.referredIn?.callerName?.forEach(pgName => {
            artiToExclude.add(pgName);
          });
        }
      });
    });

    const drivers = new Set<string>();
    programs.map(({ nodes }) => {
      nodes?.forEach(node => {
        if (node.isRootNode && !artiToExclude.has(node.id)) {
          drivers.add(node.id);
        }
      });
    });

    const includedInJcls = new Set<string>();
    jclArtifacts.map(jcl => {
      jcl[0]?.links?.forEach(stepLink => {
        if (stepLink.links?.id) {
          includedInJcls.add(stepLink.links?.id);
        }
      });
    });

    const jcls = jclArtifactIds
      .filter(id => !artiToExclude.has(id))
      .map(id => ({ name: id }))
      .sort((a, b) => a.name.localeCompare(b.name));
    const childLibraries = programIds
      .filter(
        id =>
          !artiToExclude.has(id) && !drivers.has(id) && !includedInJcls.has(id),
      )
      .map(id => ({ name: id }))
      .sort((a, b) => a.name.localeCompare(b.name));
    const driverPrograms = Array.from(drivers)
      .filter(id => !includedInJcls.has(id))
      .map(id => ({ name: id }))
      .sort((a, b) => a.name.localeCompare(b.name));

    return {
      jcls,
      childLibraries,
      driverPrograms,
    };
  }, [missingPages, jclArtifactIds, programIds, programs, jclArtifacts]);

  const jcls = data.jcls || [];
  const driverPrograms = data.driverPrograms || [];
  const otherPrograms = data.childLibraries || [];

  const onClickReport = () => {
    dispatch(setCurrentIntent(EAppIntent.TRANSFORMATION_REPORT));
  };

  return (
    <div>
      <div className="rounded-lg border bg-card text-card-foreground shadow-sm mx-6 mt-4">
        <div className="bg-white rounded-lg shadow-sm p-6 mx-auto">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-2xl font-bold"></h2>
            <div className="flex gap-2">
              {/* <button
                onClick={downloadLog}
                style={{ backgroundColor: 'rgb(19,98,223)', color: 'white' }}
                className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input hover:text-accent-foreground h-10 px-4 py-2 bg-primary text-white hover:bg-primary/90"
              >
                <span className="mr-1">
                  <MdOutlineFileDownload size={20} />
                </span>
                Download Error Logs
              </button> */}
              <HelpTextCallout calloutText={pageHelpText[0].description}/>
              <button
                className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input hover:text-accent-foreground h-10 px-4 py-2 bg-secondary text-secondary-foreground hover:bg-secondary/90"
                style={{ backgroundColor: 'rgb(19,98,223)', color: 'white' }}
                onClick={() => {
                  dispatch(setActiveModal(EAppModal.SUPPORT_TICKET));
                }}
              >
                Create Support Ticket
              </button>
              <HelpTextCallout calloutText={pageHelpText[1].description}/>
              <button
                onClick={onClickReport}
                style={{ backgroundColor: 'rgb(19,98,223)', color: 'white' }}
                className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input hover:text-accent-foreground h-10 px-4 py-2 bg-green-500 text-white hover:bg-green-600"
              >
                <span className="mr-1">
                  <HiOutlineDocumentReport size={20} />
                </span>
                Transformation Summary Report
              </button>
              {/* <button
                onClick={() => {
                  dispatch(setCurrentIntent(EAppIntent.TRANSFORM_SETTINGS));
                }}
                style={{ backgroundColor: 'rgb(19,98,223)', color: 'white' }}
                className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input hover:text-accent-foreground h-10 px-4 py-2 bg-green-500 text-white hover:bg-green-600"
              >
                <span className="mr-1">
                  <CiSettings size={20} />
                </span>
                Transformation Settings
              </button> */}
            </div>
          </div>
          <div className="h-[1px] bg-greyCardOutline my-2 mb-4 -mx-6" />
          <Summary />
        </div>
      </div>
      <div className="bg-white rounded-lg shadow-md p-6 mx-auto min-h-[20rem] w-full mt-0">
        <div className="flex flex-col gap-4">
          <Table
            title="Transformation Queue"
            description="Manage your Transformations"
            helpText={pageHelpText[2].description}
            tables={[
              {
                title: 'Jobs',
                rows: jcls,
                availableProfiles: [
                  { name: 'Default', value: 'Default' },
                  ...availableProfiles.jcl,
                ],
              },
              {
                title: 'Driver Programs',
                rows: driverPrograms,
                availableProfiles: [
                  { name: 'Default', value: 'Default' },
                  ...availableProfiles.driverPrograms,
                ],
              },
            ]}
          />
          <Table
            title="Child Libraries"
            description="Manage your program Transformations"
            helpText={pageHelpText[3].description}
            tables={[
              {
                title: 'Child Libraries',
                rows: otherPrograms,
                availableProfiles: [
                  { name: 'Default', value: 'Default' },
                  ...availableProfiles.otherPrograms,
                ],
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default TransformationPage;
