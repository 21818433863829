import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
import { FaSpinner } from 'react-icons/fa';
import axios from 'axios';
import { externalConfig } from '../../../utils/misc.utils';
import { setCurrentIntent, setNextMissingPage, setNextMissingPageTest } from '../../../redux/app-global';
import { useDispatch, useSelector } from 'react-redux';
import { EAppIntent } from '../../../redux/app-global/app-global.types';

import ReusableTable from '../../../components/missing-artifacts-table/missing-artifacts-table';
import {
    PrimaryButton,
} from '@fluentui/react';

import { RootState } from '../../../redux/store';
import StepperComponent from '../../../components/stepper-component/stepper-component';
import HelpTextCallout from '../../../components/help-text-callout/help-text-callout';
import { helpTexts } from '../../../helpTexts';

const pagHelpTexts = helpTexts[0].MISSING_ARTIFACTS;

interface Artifact {
    value: any;
    name: string;
    referredIn: {
        jcl: string[];
        proc: string[];
    };
    symbolName: string;
    requiredBy: string;
    symbolValue: string;
}

const MissingArtifacts = () => {
    const [artifacts, setArtifacts] = useState<Artifact[]>([]);
    const [error, setError] = useState("");
    const [file, setFile] = useState<File | null>(null);
    const [tableValue, setTableValue] = useState<Artifact[]>([]);
    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] = useState(true);


    const dispatch = useDispatch();


    const missingPages = useSelector((state: RootState) => state.appGlobal.missingPages);

    useEffect(() => {
        const fetchData = async () => {
            const apiUrl = `${externalConfig?.scannerUrl || process.env.REACT_APP_REPORT_API_URL}/missing-artifact/SYMBOL`;

            try {
                const response = await axios.get(apiUrl);
                const data = response.data?.data ?? response.data ?? [];
                if (Array.isArray(data)) {
                    setTableValue(data);
                    setLoading(false);
                } else {
                    setTableValue([]);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('An error occurred while fetching data.');
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const index = parseInt(e.target.dataset.index, 10);

        setTableValue(prevState => {
            const newState = [...prevState];
            newState[index] = { ...newState[index], symbolValue: value };
            return newState;
        });
    };

    const handleSubmission = async () => {
        const apiUrl = `${externalConfig?.scannerUrl || process.env.REACT_APP_REPORT_API_URL}/missing-artifact/SYMBOL`;

        try {
            const formData = new FormData();

            tableValue.forEach((artifact) => {
                formData.append(`name`, artifact.name);
                formData.append(`value`, artifact.symbolValue);
            });

            const response = await axios.post(apiUrl, formData);
            console.log(response);

            setSuccessMessage("Data successfully saved!");

            setTimeout(() => setSuccessMessage(""), 3000);

        } catch (error) {
            console.error('Error saving data:', error);
            setError('An error occurred while saving data.');

        }
        finally {
            const currentIndex = missingPages.findIndex((page) => page.status === true);
            if (currentIndex !== -1 && currentIndex < missingPages.length - 1) {
                const nextIndex = currentIndex + 1;
                const nextPage = missingPages[nextIndex];
                dispatch(setCurrentIntent(nextPage.key));
                dispatch(setNextMissingPage({
                    page: nextPage.key,
                }));
                const updatedMissingPages = missingPages.map((page, index) =>
                    index === nextIndex ? { ...page, status: true }
                        : index === currentIndex ? { ...page, status: false }
                            : page
                );
                dispatch(setNextMissingPageTest(updatedMissingPages));
            } else {
                dispatch(setCurrentIntent(EAppIntent.REPORT));
                dispatch(
                    setNextMissingPage({
                        page: undefined,
                    }),
                );
            }
        }
    };

    const allowedExtensions = ["csv"];
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setError("");
        if (e.target.files?.length) {
            const inputFile = e.target.files[0];

            const fileExtension = inputFile?.name.split('.').pop()?.toLowerCase();
            if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
                setError("Please input a CSV file");
                return;
            }
            setFile(inputFile);
            parseCSV(inputFile);
        }
    };

    const parseCSV = (file: File) => {
        Papa.parse(file, {
            header: true,
            complete: (results) => {
                const parsedData = results.data as Artifact[];
                const cleanedData = parsedData.map(artifact => ({
                    ...artifact,
                    referredIn: {
                        jcl: artifact.referredIn?.jcl || [],
                        proc: artifact.referredIn?.proc || [],
                    }
                }));
                setTableValue(cleanedData);
            },
            error: (_error) => {
                setError("Error parsing CSV file");
            }
        });
    };

    const csvData = [
        ["name", "symbolValue"],
        ...tableValue.map(artifact => [artifact.name, artifact.symbolValue])
    ];

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const headers: React.ReactNode[] = [
        'Symbol Name',
        'Required By',
        'Symbol Value',
    ];

    const renderRowFields = (artifact: Artifact) => [
        artifact.name,
        [...(artifact.referredIn?.jcl || []), ...(artifact.referredIn?.proc || [])].join(', '),
        <input
            type="text"
            name="symbolValue"
            data-index={tableValue.indexOf(artifact)}
            value={artifact.symbolValue}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-300 transition duration-300"
        />
    ];

    return (
        <>
            <div  className={`container p-6 ${isMobile ? 'sm:px-6' : 'lg:px-8'} max-w-full`}>
            <div className='p-2 px-8 mb-4'>
                    <StepperComponent />
                </div>
                <div className='mb-2 border rounded-lg px-8 py-8 shadow max-w-full'>
                <h1 className="text-3xl font-semibold mb-4">
                    Missing Artifacts Workspace
                </h1>
                <hr />
                <div className="flex flex-col gap-4">
                    <div className="text-left mt-4 gap-4 text-l font-semibold">
                        Missing JCL Symbols
                    </div>
                    <div>
                        <p>Please provide below mentioned missing symbol details</p>
                    </div>
                    <div className="flex flex-row items-center justify-end gap-2">
                        <HelpTextCallout calloutText={pagHelpTexts[8].description}/>
                        <CSVLink
                            data={csvData}
                            filename={"missing-artifact.csv"}
                            className="cursor-pointer hover:text-blue-500 rounded transition duration-300 inline-block text-blue-700 text-sm"
                            
                        >
                            Download CSV Template
                        </CSVLink>
                        <HelpTextCallout calloutText={pagHelpTexts[9].description}/>
                        <label
                            htmlFor="csvUpload"
                            className="cursor-pointer hover:text-blue-500 rounded transition duration-300 inline-block text-blue-700 text-sm"
                        >
                            Upload CSV
                        </label>
                        <input
                            id="csvUpload"
                            type="file"
                            accept=".csv"
                            onChange={handleFileChange}
                            className="hidden"
                        />
                    </div>
                   
                        {successMessage && <p className="text-green-600">{successMessage}</p>}
                        {loading ? (
                            <div className="flex flex-col items-center">
                                <FaSpinner className="animate-spin text-4xl text-gray-600" />
                                <p className="mt-4 text-gray-600">Loading...</p>
                            </div>
                        ) : tableValue.length > 0 ? (
                            <ReusableTable
                                headers={headers}
                                items={tableValue}
                                rowFields={renderRowFields}
                                helpTexts={pagHelpTexts}
                            />
                        ) : (
                            <>
                                <ReusableTable
                                    headers={headers}
                                    items={tableValue.length > 0 ? tableValue : []}
                                    rowFields={tableValue.length > 0 ? renderRowFields : () => []}
                                    helpTexts={pagHelpTexts}
                                />
                                <p className="text-gray-600">No data available</p>
                            </>
                        )}
                    <div className="flex">
                        <PrimaryButton
                            onClick={handleSubmission}
                            text={'Save and Continue'}
                            className="mt-8 rounded-md"
                            styles={{ root: { backgroundColor: '#1363DF' } }}
                        />
                    </div>
                </div>
            </div>
            </div>
        </>
    );
};

export default MissingArtifacts;
