import React from 'react';

const MessageParser = ({ children, actions }: any) => {
  const parse = (message: any) => {
    actions.handlePrompt(message);
    // if (['contact'].includes(message.toLowerCase())) {
    //   actions.getUserInfo();
    // } else if (['artifact', 'upload'].includes(message.toLowerCase())) {
    //   actions.handleUploadArtifact();
    // } else {
    //   actions.handlePrompt(message);
    // }
  };

  return (
    <div>
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          parse: parse,
          actions: {},
        });
      })}
    </div>
  );
};

export default MessageParser;
