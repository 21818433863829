import { EAppIntent } from "./redux/app-global/app-global.types";

export const helpTexts = [
    {
        [EAppIntent.EXTRACT_ARTIFACT]: [
            {
                id: 1,
                description: 'Please enter the names of the JCLs to be extracted from the mainframe. Use the "+" option to add dataset names. To remove unwanted datasets, click the "-" option beside the corresponding dataset entries.'
            },
            {
                id: 2,
                description: 'Please enter the names of the JCL PROC to be extracted from the mainframe. Use the "+" option to add dataset names. To remove unwanted datasets, click the "-" option beside the corresponding dataset entries.'
            },
            {
                id: 3,
                description: 'Please enter the names of the JCL Control Cards to be extracted from the mainframe. Use the "+" option to add dataset names. To remove unwanted datasets, click the "-" option beside the corresponding dataset entries.'
            },
            {
                id: 4,
                description: 'Please enter the names of the Compiler Listings to be extracted from the mainframe. Use the "+" option to add dataset names. To remove unwanted datasets, click the "-" option beside the corresponding dataset entries.'
            },
            {
                id: 5,
                description: 'Please enter the names of the Compiler Metadata to be extracted from the mainframe. Use the "+" option to add dataset names. To remove unwanted datasets, click the "-" option beside the corresponding dataset entries.'
            },
            {
                id: 6,
                description: 'Click this option to generate the JCL required to download artifacts from the mainframe. Before downloading, ensure that you have provided the necessary dataset names in each box to create the JCL.'
            },
            {
                id: 7,
                description: 'Select an option from the dropdown to specify how you want to download the JCL. Choosing "Single JCL for All Artifacts" will generate one JCL file for all artifacts. If you need separate JCLs for each artifact, select the required extraction option from the dropdown.'
            },
            {
                id: 8,
                description: 'Click this button when you are ready to upload the artifacts that you have extracted from your mainframe. This will initiate the next step in the modernization process, where you can begin working with your uploaded artifacts.'
            }

        ],
        [EAppIntent.REPORT]: [
            {
                id: 1,
                description: 'Use this option to view and interact with call chain graphs on the canvas. This feature allows you to visually explore the call chains and their relationships within the application.'
            },
            {
                id: 2,
                description: 'Click this option to quickly view the steps of the JCL, illustrating how each step calls other programs. This graphical representation helps you understand the flow and interactions within the JCL.'
            },
            {
                id: 3,
                description: 'Some artifacts are missing to generate the code. Clicking on this button will navigate to the Missing Artifact Workspace, where you can add the missing artifact.'
            },
        ],
        [EAppIntent.MISSING_PROC]: [
            {
                id: 1,
                description: 'Check this option to ignore all the listed missing PROCs in the current transformation process. You can also select individual rows to ignore specific PROCs.'
            },
            {
                id: 2,
                description: 'This column lists the names of the missing PROCs that are required for the transformation process. Each PROC represents a procedure needed by the JCL or another PROC.'
            },
            {
                id: 3,
                description: 'This column indicates the specific JCL or PROC that depends on the listed PROC. It helps you identify which procedures are essential for the correct execution of the JCL or PROC.'
            },
            {
                id: 4,
                description: 'Use this option to upload the missing PROC directly from your local system. Upload the corresponding PROC file to resolve the missing information for each listed item.'
            },
            {
                id: 5,
                description: 'This switch allows you to choose the appropriate option to proceed with resolving the missing proc information. The "Upload Procs" option lets you manually upload the missing proc files from your local system. The "Extract Procs from Mainframe" option enables you to enter the dataset names to initiate the extraction process of the missing Proc from MainFrame.'
            },
            {
                id: 6,
                description: 'This option allows you to efficiently manage and upload several PROCs at once. Use this option to upload the missing PROCs in a compressed format, such as a ZIP file or XMI.'
            },
            {
                id: 7,
                description: 'This option allows you to generate the JCL needed to extract the missing PROC from the mainframe. The system will generate the JCL based on the provided dataset names.'
            },
        ],
        [EAppIntent.MISSING_CONTROLCARD]: [
            {
                id: 1,
                description: 'Check this option to ignore all the listed missing Control Cards in the current transformation process. You can also select individual rows to ignore specific Control Cards.'
            },
            {
                id: 2,
                description: 'This column lists the names of the missing Control Cards that are required for the transformation process. Each Control Card represents a set of instructions or parameters needed by the JCL or another Control Card.'
            },
            {
                id: 3,
                description: 'This column indicates the specific JCL or PROC that depends on the listed Control Card. It helps you identify which Control Cards are essential for the correct execution of the JCL or PROC.'
            },
            {
                id: 4,
                description: 'Use this option to upload the missing Control Card directly from your local system. Upload the corresponding Control Card file to resolve the missing information for each listed item.'
            },
            {
                id: 5,
                description: 'This switch allows you to choose the appropriate option to proceed with resolving the missing Control Card information. The "Upload Control Cards" option lets you manually upload the missing Control Card files from your local system. The "Extract Control Cards from Mainframe" option enables you to enter the dataset names to initiate the extraction process of the missing Control Card from MainFrame.'
            },
            {
                id: 6,
                description: 'This option allows you to efficiently manage and upload several Control Cards at once. Use this option to upload the missing Control Cards in a compressed format, such as a ZIP file or XMI.'
            },
            {
                id: 7,
                description: 'This option allows you to generate the JCL needed to extract the missing Control Card from the mainframe. The system will generate the JCL based on the provided dataset names.'
            },
        ],
        [EAppIntent.MISSING_PROGRAM]: [
            {
                id: 1,
                description: 'This dropdown lets you manage the status of each listed program. Select Active to resolve the missing program for the transformation process, Ignore to exclude it, or Assembler to specify that it’s acceptable to use stubs for the missing programs. You can apply these settings either globally for all programs or individually for each program in the respective rows.'
            },
            {
                id: 2,
                description: 'This column lists the names of the missing Programs that are required for the transformation process. Each Program is an essential component or application needed by the JCL or another Program.'
            },
            {
                id: 3,
                description: 'This column indicates the specific Program that calls or depends on the listed Program. It helps you identify which Programs are essential for the correct execution of the calling Program.'
            },
            {
                id: 4,
                description: 'Use this option to upload the missing Program directly from your local system. Upload the corresponding Program file to resolve the missing information for each listed item.'
            },
            {
                id: 5,
                description: 'This switch allows you to choose the appropriate option to proceed with resolving the missing programs information. The "Upload Programs" option lets you manually upload the missing programs from your local system. The "Extract Control Programs from Mainframe" option enables you to enter the dataset names to initiate the extraction process of the missing programs.'
            },
            {
                id: 6,
                description: 'This option allows you to efficiently manage and upload several Programs at once. Use this option to upload the missing Programs in a compressed format, such as a ZIP file or XMI.'
            },
            {
                id: 7,
                description: 'This option allows you to generate the JCL needed to extract the missing Program from the mainframe. The system will generate the JCL based on the provided dataset names.'
            },
            {
                id: 8,
                description: 'Use the following table to specify any naming conventions or patterns where an external program name differs from its internal counterpart. This allows the system to correctly identify and map programs that follow specific naming rules.'
            },
            {
                id: 9,
                description: 'Use this option to download a CSV template that you can fill in with the required name mappings. The template provides the necessary structure for entering external and internal program names or patterns.'
            },
            {
                id: 10,
                description: 'Use this option to upload the file, once you have filled out the CSV template with the name mappings. The system will use the provided mappings to correctly identify and process the programs.'
            },
            {
                id: 11,
                description: 'This column allows you to enter the external name or naming pattern of the program. This could be a specific name, or a pattern used to identify a set of programs that serve as aliases for internal programs.'
            },
            {
                id: 12,
                description: 'This column allows you to enter the internal name or naming pattern of the program that corresponds to the external name. The system will map the external names to these internal program names for accurate processing.'
            },
        ],
        [EAppIntent.RESOLVE_DYNAMIC_CALLS]: {
            programTableData: [
                {
                    id: 1,
                    description: 'This dropdown lets you manage the status of each listed program. Select Active to resolve the missing program for the dynamic call, Ignore to exclude it, or Assembler to specify that it’s acceptable to use stubs for the missing programs. You can apply these settings either globally for all programs or individually for each program in the respective rows.'
                },
                {
                    id: 2,
                    description: 'This column lists the names of the missing Programs that are required for the transformation process. Each Program is an essential component or application needed by the JCL or another Program.'
                },
                {
                    id: 3,
                    description: 'This column indicates the specific Program that calls or depends on the listed Program. It helps you identify which Programs are essential for the correct execution of the calling Program.'
                },
                {
                    id: 4,
                    description: 'Use this option to upload the missing Program directly from your local system. Upload the corresponding Program file to resolve the missing information for each listed item.'
                },
                {
                    id: 5,
                    description: 'This switch allows you to choose the appropriate option to proceed with resolving the missing programs information. The "Upload Programs" option lets you manually upload the missing programs from your local system. The "Extract Control Programs from Mainframe" option enables you to enter the dataset names to initiate the extraction process of the missing programs.'
                },
                {
                    id: 6,
                    description: 'This option allows you to efficiently manage and upload several Programs at once. Use this option to upload the missing Programs in a compressed format, such as a ZIP file or XMI.'
                },
                {
                    id: 7,
                    description: 'This option allows you to generate the JCL needed to extract the missing Program from the mainframe. The system will generate the JCL based on the provided dataset names.'
                },
                {
                    id: 8,
                    description: 'Use the following table to specify any naming conventions or patterns where an external program name differs from its internal counterpart. This allows the system to correctly identify and map programs that follow specific naming rules.'
                },
                {
                    id: 9,
                    description: 'Use this option to download a CSV template that you can fill in with the required name mappings. The template provides the necessary structure for entering external and internal program names or patterns.'
                },
                {
                    id: 10,
                    description: 'Use this option to upload the file, once you have filled out the CSV template with the name mappings. The system will use the provided mappings to correctly identify and process the programs.'
                },
                {
                    id: 11,
                    description: 'This column allows you to enter the external name or naming pattern of the program. This could be a specific name, or a pattern used to identify a set of programs that serve as aliases for internal programs.'
                },
                {
                    id: 12,
                    description: 'This column allows you to enter the internal name or naming pattern of the program that corresponds to the external name. The system will map the external names to these internal program names for accurate processing.'
                },
            ],
            dynamicResolveTable: [
                {
                    id: 1,
                    description: 'This column lists the variables used in dynamic calls within the program. These variables represent the program names or parts of program names that are determined at runtime, and resolving them is necessary for accurate transformation.'
                },
                {
                    id: 2,
                    description: 'This column indicates the specific program that uses the dynamic call through the listed Program Variable. It helps you identify which Caller Program is dependent on the resolution of the dynamic call.'
                },
                {
                    id: 3,
                    description: 'Please select the correct program name from already uploaded programs to resolve the dynamic calls.'
                },
            ]
        },
        [EAppIntent.TRANSFORMATION_PAGE]: [
            {
                id: 1,
                description: "Use this option to create a support ticket if you encounter any issues or need assistance during the transformation phase. CloudFrame's customer support team will reach out to guide you through your request and provide the necessary support."
            },
            {
                id: 2,
                description: 'The Transformation Summary Report highlights programs that were successfully transformed and identifies those that encountered challenges. This report serves as an essential tool for assessing the transformation outcomes and informing your future strategic planning. It also offers a comprehensive overview of all support tickets raised during the transformation process.'
            },
            {
                id: 3,
                description: 'The Transform section is your control center for managing the migration of programs ready for transformation. This section is organized into multiple categories, such as Jobs, Programs, and Driver Programs, based on the artifacts you have provided. Each category lists the respective programs that are prepared for migration. You can choose to initiate the migration process individually for each artifact within these categories or collectively for all artifacts in the section. This flexibility allows you to tailor the transformation process to your specific needs, ensuring that each program is migrated according to your timeline and priorities.'
            },
            {
                id: 4,
                description: 'The Child Libraries displays child programs that are part of artifacts in other sections. These child programs are already included in the main transformation process. However, if you wish to download the code for a specific child program separately, you can do so using this section. This feature provides flexibility, allowing you to access and manage individual child programs as needed, independent of the main artifact transformations.'
            },
            {
                id: 5,
                description: ''
            },
        ],
        ['INFORMATION_CENTER']: [
            {
                id: 1,
                description: 'The Information Center provides valuable insights and resources guiding you through each phase of the process for modernization. This interactive step-by-step wizard helps to ensure a smooth and efficient transition from legacy systems to modern solutions.'
            },
            {
                id: 2,
                description: 'Click this button to access the Missing Artifact Workspace, where you can resolve any missing artifacts identified during the Readiness phase. Please address all missing artifacts before proceeding to the Transform phase. This ensures a smooth and complete transformation process.'
            }
        ],
        [EAppIntent.MISSING_ARTIFACTS]: [
            {
                id: 1,
                description: 'This column lists the missing JCL symbols that need to be defined for the transformation process.'
            },
            {
                id: 2,
                description: 'This column indicates the specific JCL or PROC that requires the corresponding symbol. It helps you identify where each symbol is needed in the transformation process.'
            },
            {
                id: 3,
                description: 'Please enter the value for the corresponding missing symbol in this field. The value you provide will be used to replace the missing symbol in the corresponding JCL or PROC.'
            },
            {
                id: 4,
                description: 'Use this option to upload the missing Program directly from your local system. Upload the corresponding Program file to resolve the missing information for each listed item.'
            },
            {
                id: 5,
                description: 'This switch allows you to choose the appropriate option to proceed with resolving the missing programs information. The "Upload Programs" option lets you manually upload the missing programs from your local system. The "Extract Control Programs from Mainframe" option enables you to enter the dataset names to initiate the extraction process of the missing programs.'
            },
            {
                id: 6,
                description: 'This option allows you to efficiently manage and upload several Programs at once. Use this option to upload the missing Programs in a compressed format, such as a ZIP file or XMI.'
            },
            {
                id: 7,
                description: 'This option allows you to generate the JCL needed to extract the missing Program from the mainframe. The system will generate the JCL based on the provided dataset names.'
            },
            {
                id: 8,
                description: 'Click this option to download a CSV template. The template allows you to input symbol values offline. Once completed, you can upload the CSV to automatically populate the symbol values on the page.'
            },
            {
                id: 9,
                description: 'Use this option to upload a completed CSV file with the symbol values. The uploaded file will populate the symbol values on the page, saving you time from entering them manually.'
            },
            {
                id: 10,
                description: 'Use this option to upload the file, once you have filled out the CSV template with the name mappings. The system will use the provided mappings to correctly identify and process the programs.'
            },
            {
                id: 11,
                description: 'This column allows you to enter the external name or naming pattern of the program. This could be a specific name, or a pattern used to identify a set of programs that serve as aliases for internal programs.'
            },
            {
                id: 12,
                description: 'This column allows you to enter the internal name or naming pattern of the program that corresponds to the external name. The system will map the external names to these internal program names for accurate processing.'
            },
        ],
        [EAppIntent.ARTIFACT_UPLOAD] : [
            {
                id:1,
                description: 'Click this button once you have successfully uploaded the artifacts extracted from your mainframe. This will move you forward to the next step in the modernization process.'
            }
        ],
        [EAppIntent.MISSING_METADATA] : [
                {
                    id: 1,
                    description: 'Check this option to ignore all the listed missing Metadata items in the current transformation process. You can also select individual rows to ignore specific metadata entries.'
                },
                {
                    id: 2,
                    description: 'This column lists the names of the missing Metadata that are required for the transformation process. Each Metadata entry represents a set of data definitions or parameters needed by the JCL or another Metadata item.'
                },
                {
                    id: 3,
                    description: 'This column indicates the specific JCL or PROC that depends on the listed Metadata. It helps you identify which Metadata items are essential for the correct execution of the JCL or PROC.'
                },
                {
                    id: 4,
                    description: 'This column specifies the length of each record in the dataset. Enter the appropriate value to ensure the correct processing of data during transformation.'
                },
                {
                    id: 5,
                    description: 'This column indicates the format of the records in the dataset (e.g., Fixed, Variable). Select the correct format to match your dataset’s structure for accurate transformation.'
                },
                {
                    id: 6,
                    description: 'This represents the name of the data cluster associated with the missing artifact. Ensure that the correct cluster name is provided to accurately link the metadata during the transformation process.'
                },
                {
                    id: 7,
                    description: 'This option allows you to generate the JCL needed to extract the missing Metadata from the mainframe. The system will generate the JCL based on the provided dataset names.'
                }
        ]
    }
]