import React, { useRef, useEffect, useState } from 'react';
import Select from '../select/select.component';
import { appTheme } from '../../styles/theme';
import GraphLegend from '../graph-legend/graph-legend';

const CanvasComponent = ({
  svgString,
  programIds,
  jclArtifactIds,
  selectedProgramId,
  setSelectedProgramId,
  usedColors,
  inModal = false,
}: any) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [transform, setTransform] = useState({ scale: 1, dx: 0, dy: 0 });
  const imgRef = useRef<HTMLImageElement | null>(null);

  // Define the resolution multiplier
  const resolutionMultiplier = 2;

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    const img = new Image();
    imgRef.current = img;
    const svgBlob = new Blob([svgString], {
      type: 'image/svg+xml;charset=utf-8',
    });
    const url = URL.createObjectURL(svgBlob);

    img.onload = () => {
      if (!ctx) return;
      // Adjust canvas internal size based on the multiplier
      canvas.width = 1000 * resolutionMultiplier;
      canvas.height = 500 * resolutionMultiplier;

      // Fit the image into the canvas
      const scaleWidth = canvas.width / img.width;
      const scaleHeight = canvas.height / img.height;
      const scale = Math.min(scaleWidth, scaleHeight);
      const dx = (canvas.width - img.width * scale) / 2;
      const dy = (canvas.height - img.height * scale) / 2;
      setTransform({ scale, dx, dy });

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, dx, dy, img.width * scale, img.height * scale);
      URL.revokeObjectURL(url);
    };
    img.src = url;
  }, [svgString, resolutionMultiplier]);

  const redraw = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx || !imgRef.current) return;

    ctx.setTransform(1, 0, 0, 1, 0, 0); // Reset transformations
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas

    ctx.translate(transform.dx, transform.dy); // Apply translation
    ctx.scale(transform.scale, transform.scale); // Apply scale
    ctx.drawImage(imgRef.current, 0, 0); // Redraw image
  };

  const handleWheel = e => {
    e.preventDefault();
    e.stopPropagation();
    const canvas = canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    const delta = e.deltaY < 0 ? 1.05 : 0.95; // Slower zoom

    setTransform(prev => {
      const scale = prev.scale * delta;
      const dx = mouseX - (mouseX - prev.dx) * delta;
      const dy = mouseY - (mouseY - prev.dy) * delta;
      return { scale, dx, dy };
    });
  };

  const zoomToFit = () => {
    const canvas = canvasRef.current;
    const img = imgRef.current;
    if (!canvas || !img) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Calculate scale and translation to fit the image into the canvas
    const scaleWidth = canvas.width / img.width;
    const scaleHeight = canvas.height / img.height;
    const scale = Math.min(scaleWidth, scaleHeight);
    const dx = (canvas.width - img.width * scale) / 2;
    const dy = (canvas.height - img.height * scale) / 2;
    setTransform({ scale, dx, dy });
  };

  useEffect(() => {
    redraw(); // Redraw on transform change
  }, [transform, resolutionMultiplier]);

  const handleMouseMove = e => {
    if (e.buttons !== 1) return; // Only proceed if left mouse button is pressed
    setTransform(prev => ({
      ...prev,
      dx: prev.dx + e.movementX * resolutionMultiplier, // Adjust for resolutionMultiplier
      dy: prev.dy + e.movementY * resolutionMultiplier, // Adjust for resolutionMultiplier
    }));
  };

  return (
    <div
      className={`w-[100%] ${inModal ? '' : 'shadow-lg'} p-4 flex flex-col-reverse rounded-md`}
      style={{
        overflow: 'hidden',
        cursor: 'pointer',
        border: `1px solid ${appTheme.colors.grey20}`,
        position: 'relative',
        height: 'calc(100% - 4rem)',
      }}
    >
      <div className="mt-4">
        <GraphLegend inModal={inModal} usedColors={usedColors} />
      </div>
      <div className="absolute top-2 bg-grey10 p-2 rounded-md -ml-2">
        <Select
          selectedProgramId={selectedProgramId}
          setSelectedProgramId={setSelectedProgramId}
          options={[
            { label: 'Program', options: programIds },
            { label: 'JCL', options: jclArtifactIds },
          ]}
        />
      </div>
      <button
        onClick={zoomToFit}
        className="px-4 py-2 rounded-md shadow-sm text-sm absolute bg-white"
        style={{
          alignSelf: 'flex-start',
          border: `1px solid ${appTheme.colors.grey20}`,
        }}
      >
        Fit into Canvas
      </button>
      <canvas
        style={{
          width: '1000px', // Display size
          height: '500px', // Display size
          maxWidth: '100%', // Ensure it's responsive
          maxHeight: '100%', // Ensure it's responsive
        }}
        ref={canvasRef}
        // width={1000}
        // height={500}
        onWheel={handleWheel}
        onMouseMove={handleMouseMove}
      />
    </div>
  );
};

export default CanvasComponent;
