import { findBestMatch } from '../../services/string-match';

const questions = [
  'hi',
  'hello',
  'Give me the top 3 most complex programs',
  'List all the program called by TRDPB000',
  'List the programs that deletes rows from table TBTRDSTQ',
  'Which program uses MQ?',
  'Describe the logic for program TRDBP000 in 50 words or less',
  'Give me the most complex program',
];

function findClosestQuestion(userInput: string): string | null {
  const processedInput = userInput.toLowerCase().trim();
  const match = findBestMatch(processedInput, questions);
  const threshold = 0.5;

  if (match.bestMatch.rating >= threshold) {
    return match.bestMatch.target;
  } else {
    return null;
  }
}

export const mockResponse: Record<string, { message: string }> = {
  hi: {
    message: "Hello, I'm Code Navigator. How can I help you?",
  },
  hello: {
    message: "Hello, I'm Code Navigator. How can I help you?",
  },
  'Give me the top 3 most complex programs': {
    message:
      'Sure.. the top three most complex programs are 1. TRDPB000 2. TRDPB001 & 3. TRDPB004',
  },
  'List all the program called by TRDPB000': {
    message: 'TRDPB000 calls TRDPBEXC, MSTPB001, MSTPB002',
  },
  'programs called by TRDPB000': {
    message: 'TRDPB000 calls TRDPBEXC, MSTPB001, MSTPB002',
  },
  'List the programs that deletes rows from table TBTRDSTQ': {
    message: 'Programs TRDPB001 & TRDPB004 DELETEs from table TBTRDSTQ',
  },
  'deletes from table TBTRDSTQ': {
    message: 'Programs TRDPB001 & TRDPB004 DELETEs from table TBTRDSTQ',
  },
  'Which program uses MQ?': {
    message:
      'TRDPB007 uses MQ, It connects to MQ using MQCONN, puts message using MQPUT1 and then disconnects using MQDISC API',
  },
  'Describe the logic for program TRDBP000 in 50 words or less': {
    message:
      'This program processes trading orders by validating and matching Buy/Sell orders based on currency codes (e.g., TUSD, TCHF, TINR). It supports multi-threading for currency-specific settlements, logs validation issues in TBTRDEXP, and completes settlement batches once trades are matched.',
  },
  'Give me the most complex program': {
    message:
      'Most complex program is 1000-ORDER-ACCEPTANCE in program TRDBP000 with cyclomatic complexity of 40',
  },
  complex: {
    message:
      'Sure.. the top three most complex programs are 1. TRDPB000 2. TRDPB001 & 3. TRDPB004',
  },
  'called by TRDPB000': {
    message: 'TRDPB000 calls TRDPBEXC, MSTPB001, MSTPB002',
  },
  'deletes rows from table TBTRDSTQ': {
    message: 'Programs TRDPB001 & TRDPB004 DELETEs from table TBTRDSTQ',
  },
  MQ: {
    message:
      'TRDPB007 uses MQ, It connects to MQ using MQCONN, puts message using MQPUT1 and then disconnects using MQDISC API',
  },
  'logic for program TRDBP000': {
    message:
      'This program processes trading orders by validating and matching Buy/Sell orders based on currency codes (e.g., TUSD, TCHF, TINR). It supports multi-threading for currency-specific settlements, logs validation issues in TBTRDEXP, and completes settlement batches once trades are matched.',
  },
  'most complex program': {
    message:
      'Most complex program is 1000-ORDER-ACCEPTANCE in program TRDBP000 with cyclomatic complexity of 40',
  },
};

export function getMockResponse(question: string): string {
  const qp = findClosestQuestion(question);
  if (qp && mockResponse[qp]?.message) {
    return mockResponse[qp]?.message;
  }
  return "Sorry, I don't have an answer for that.";
}
